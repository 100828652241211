// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ADMIN, ENV_COMMUNICATION } from 'config/Enviroment';

import APIClient from '../ApiClient';

const saveReadReceipt = async (threadId: string, messageId: number) => {
  const { data } = await APIClient.post<void>(
    ENV_COMMUNICATION.CREATE_MESSAGE_CHECKMARK(threadId),
    { messageId }
  );

  return data;
};

export function useSaveReadReceipt(threadId: string) {
  const queryClient = useQueryClient();

  const saveReadReceiptMutation = useMutation<void, AxiosError<string, string>, number>(
    (messageId: number) => saveReadReceipt(threadId, messageId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_COMMUNICATION.GET_ALL_THREAD);
        queryClient.invalidateQueries(ENV_ADMIN.GET_ALL_THREAD);
      },
    }
  );

  return {
    saveReadReceiptMutation,
  };
}
