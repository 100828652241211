import { Fragment, useCallback } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { IMediaTableRow } from 'interfaces';

interface MediaModalProps {
  open: boolean;
  data: IMediaTableRow;
  onClose: () => void;
}

export function MediaModalAdmin(props: MediaModalProps): JSX.Element {
  const { open, onClose, data } = props;
  const { bunnyId, fileUrl, type, libraryId } = data;

  const renderVideoPlayer = useCallback(() => {
    return (
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          title="123"
          src={`https://iframe.mediadelivery.net/embed/${libraryId}/${bunnyId}?autoplay=true`}
          loading="lazy"
          style={{
            height: '100%',
            width: '100%',
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  }, [bunnyId, libraryId]);

  const renderPhotoViewer = useCallback(() => {
    return (
      <img
        className="rounded-t-lg w-full h-full object-cover"
        src={fileUrl ? fileUrl : 'http://via.placeholder.com/200x200'}
        alt="no preview"
      />
    );
  }, [fileUrl]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-black rounded-md pb-4 text-left overflow-hidden shadow-xl transition-all sm:align-middle sm:p-2 max-w-screen-lg w-screen">
              <div className="sm:block absolute top-0 right-0 pt-4 pr-4 z-20">
                <button
                  type="button"
                  className="bg-white rounded-md shadow-sm text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  onClick={() => onClose()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {(type === 'PHOTO' || type === 'COVER') && renderPhotoViewer()}
              {type === 'VIDEO' && renderVideoPlayer()}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
