import { useMemo } from 'react';

// 3p
import { NavLink } from 'react-router-dom';

// app
import { IChatThread } from 'interfaces';

import { UserImage } from '../image';
import { formatDateDistance } from 'utils';
import { useAuth } from 'hooks';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import { BadgeTypeEnum } from 'config';

interface IChatThreadItemProps extends IChatThread {
  onClick: (id: string) => string;
}

export const ChatThreadItem = (props: IChatThreadItemProps) => {
  const { id, participants, message, onClick } = props;

  const { user } = useAuth();

  const names = participants.map(({ user }) => user.username).join(', ');

  const images = participants.reduce((prev, { user }) => {
    if (user.image) {
      return prev.concat([user.image.url]);
    }
    return prev;
  }, [] as string[]);

  // Is Online
  let isUserOnline = false;
  if (participants && participants.length === 1) {
    isUserOnline = participants[0].user.lastAccess?.isOnline;
  }

  // Message Data
  const [messageContent, isMessageRead, messageTimeFormatted] = useMemo(() => {
    const { sendAt, content, checkmark, senderCommunicationUserId } = message;

    const messageContent = content ? content : null;

    let messageSentAt = null;
    if (sendAt) {
      const { distance, seconds } = formatDateDistance(sendAt);
      messageSentAt = seconds > 180 ? distance : 'Poco fa';
    }

    let isMessageRead = false;
    if (user) {
      const { communicationUserId } = user;
      isMessageRead = senderCommunicationUserId === communicationUserId;
    }

    if (!isMessageRead) {
      // If checkmark exsist, the message is read
      isMessageRead = !!checkmark;
    }

    return [messageContent, isMessageRead, messageSentAt];
  }, [message, user]);

  const renderParticipants = () => {
    return participants.map(({ user }, i) => {
      return (
        <>
          <p className={`${!isMessageRead ? 'font-bold' : ''}`}>{user.username}</p>
          {user.badge &&
            user.badge.code &&
            user.badge.code === BadgeTypeEnum.PAYING_CUSTOMER && (
              <BadgeCheckIcon
                className="h-4 w-4 ml-0.5"
                aria-hidden="true"
                color="#3897f0"
              />
            )}
          {i === 0 && participants.length > 1 && <span className="mr-0.5">,</span>}
        </>
      );
    });
  };

  const lastseen = false;

  return (
    <NavLink
      to={onClick(id)}
      className={({ isActive }) =>
        `${
          isActive ? 'bg-gray-200' : 'hover:bg-gray-100'
        } flex justify-between items-center p-3 rounded-lg relative cursor-pointer`
      }
    >
      <div className="w-16 h-16 relative flex flex-shrink-0">
        {images && images.length > 1 ? (
          <>
            <img
              className="shadow-md rounded-full w-10 h-10 object-cover absolute ml-6"
              src={images[0]}
              alt="User2"
            />
            <img
              className="shadow-md rounded-full w-10 h-10 object-cover absolute mt-6"
              src={images[1]}
              alt="User2"
            />
          </>
        ) : (
          <UserImage
            alt={images[0]}
            params={{ class: 'small' }}
            image={{ type: 'image', url: images[0] }}
          />
        )}
        {isUserOnline && (
          <div className="absolute bg-white p-1 rounded-full bottom-0 right-0">
            <div className="bg-green-500 rounded-full w-3 h-3"></div>
          </div>
        )}
      </div>
      <div className="flex-auto min-w-0 ml-4 mr-6 block">
        <div className="flex">{renderParticipants()}</div>
        <div
          className={`flex items-center text-sm  ${
            !isMessageRead ? 'font-bold' : 'text-gray-600'
          }`}
        >
          <div className="min-w-0 flex-1">
            <p className="truncate">{messageContent}</p>
          </div>
          <p className="ml-2 whitespace-nowrap">
            {messageTimeFormatted ? `${messageTimeFormatted}` : ''}
          </p>
        </div>
      </div>
      {!isMessageRead && (
        <div className="bg-pink-500 w-3 h-3 rounded-full flex flex-shrink-0"></div>
      )}
      {lastseen && (
        <div className="w-4 h-4 flex flex-shrink-0 hidden md:block">
          <img className="rounded-full w-full h-full object-cover" alt={names} src={''} />
        </div>
      )}
    </NavLink>
  );
};
