import { FormEvent, Fragment, useEffect, useState } from 'react';

//3p
import axios from 'axios';

import { Dialog, Transition } from '@headlessui/react';
import { UploadIcon, XIcon } from '@heroicons/react/outline';

// app
import { IMedia } from 'interfaces';
import { ACCEPTED_EXT, IMAGE_EXT } from 'config';
import { fromByteToMb, fromMbToGb, getFileType } from 'utils';

import { AlertError, Loader } from 'components/common';

import { useSaveMedia, useSaveCover } from 'api/media';

import { MediaPreview } from './MediaPreview';

import APIClient from 'api/ApiClient';

interface MediaUploadModalProps {
  open: boolean;
  isCover?: boolean;
  data?: IMedia;
  albumId: string;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: (error: Error, variables: File[], context: unknown) => void;
}

export function MediaUploadModal(props: MediaUploadModalProps): JSX.Element {
  const { open, onSuccess, onClose, onError, albumId, isCover } = props;

  const { saveMediaMutation } = useSaveMedia(albumId);
  const { saveCoverMutation } = useSaveCover(albumId);

  const { isLoading, isError, error, mutateAsync } = saveMediaMutation;
  const {
    isLoading: isLoadingCover,
    isError: isErrorCover,
    error: errorCover,
    mutate: uploadCover,
  } = saveCoverMutation;

  const [mediaList, setMediaList] = useState<File[]>([]);
  const [isLoadingDO, setIsLoadingDO] = useState<boolean>(false);

  const handleRemoveMedia = (file: File) => {
    setMediaList((prevState) => {
      const newState = [...prevState];
      const index = prevState.indexOf(file);
      newState.splice(index, 1);
      return newState;
    });
  };

  const clearOnClose = () => {
    if (isLoading) return;

    setMediaList([]);
    onClose();
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isLoading) return;

    if (!mediaList || mediaList.length === 0) return;

    setIsLoadingDO(true);

    let fileSizeSum = 0;
    for (const media of mediaList) {
      const fileSizeMb = fromByteToMb(media.size);
      fileSizeSum += fileSizeMb;

      if (!ACCEPTED_EXT.includes(media.type)) {
        alert("L'estensione del file " + media.name + ' non è supportata. Riprovare');
        setIsLoadingDO(false);
        return;
      }
    }

    if (fileSizeSum > 5000) {
      alert(
        "E' possibile caricare contemporaneamente fino a 5Gb. Hai caricato " +
          fromMbToGb(fileSizeSum).toFixed(2) +
          'Gb. Riprovare.'
      );
      setIsLoadingDO(false);
      return;
    }

    let presignedUploadPromises = [];

    for (const media of mediaList) {
      const presignedUploadPromise = (async () => {
        const data = await getSignedUrl(media.type);
        await uploadFile(data, media);
        return { media, data };
      })();

      presignedUploadPromises.push(presignedUploadPromise);
    }

    try {
      // Output: [{ status: 'fulfilled', value: 3 }, { status: 'rejected', reason: 'foo' }, { status: 'fulfilled', value: 5 }]
      const uploads = await Promise.allSettled(presignedUploadPromises);

      let mediaArray = [];

      for (const result of uploads) {
        const { status } = result;

        if (status === 'rejected') {
          alert(
            "Non è stato possibile effettuare l'upload del file selezionato. Contattare l'assistenza per maggiori informazioni."
          );
          return;
        }

        const {
          value: { data, media },
        } = result;

        const { fileName } = data;
        const type = getFileType(media);

        mediaArray.push({
          file: type === 'image' ? media : undefined,
          fileName: fileName,
          fileType: type,
          mimeType: media.type,
        });
      }

      mutateAsync({ mediaList: mediaArray }, { onSuccess: onSuccess, onError: onError });
    } catch (e) {
    } finally {
      setIsLoadingDO(false);
    }
  };

  const onSubmitCover = async (e: FormEvent) => {
    e.preventDefault();

    if (isLoading) return;

    if (!mediaList || mediaList.length === 0) return;

    if (mediaList.length > 1) {
      alert("E' possibile caricare una sola copertina per album. Riprovare");
      return;
    }
    for (const media of mediaList) {
      const fileSizeMb = fromByteToMb(media.size);
      if (fileSizeMb > 5) {
        alert('Il file ' + media.name + ' supera il limite massimo di 5Mb. Riprovare');
        return;
      }

      if (!IMAGE_EXT.includes(media.type)) {
        alert(
          "L'estensione del file " +
            media.name +
            ' non è supportata. Per la copertina è possibile caricare immagini jpeg o jpg. Riprovare'
        );
        return;
      }
    }

    //TODO: inviare cover come singolo file e non come array -> cambiare anche il servizio multiple:false
    uploadCover(
      { mediaList },
      {
        onSuccess: onSuccess,
        onError: onError,
      }
    );
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files) return;

    setMediaList((mediaList) => {
      const newMediaList = Array.from(files);
      return [...mediaList, ...newMediaList];
    });
  };

  const getSignedUrl = async (fileType: string) => {
    const body = {
      fileType: fileType,
    };

    const { data } = await APIClient.post<any>('/albums/getPresignedUrl', body, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return data;
  };

  const uploadFile = async (signedUrlData: any, file: File) => {
    try {
      const { presignedUrl, fileName } = signedUrlData;

      const fileToUpload = new File([file], fileName, { type: file.type });

      const res = await axios.put<any>(presignedUrl, fileToUpload, {
        headers: {
          'Content-Type': file.type,
        },
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const renderMediaPreview = (file: File) => {
    return (
      <MediaPreview
        key={file.name}
        file={file}
        onRemove={handleRemoveMedia}
        //isUploaded={file.isUploaded}
      />
    );
  };

  // Set Default data or clean
  useEffect(() => {
    setMediaList([]);
  }, [open]);

  const renderLoader = () => (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
          Caricamento
        </Dialog.Title>
        <div className="mt-2">
          <Loader />
        </div>
      </div>
    </div>
  );

  const renderError = () => (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
          ERRORE
        </Dialog.Title>
        <div className="mt-2">
          <AlertError message="Errore generico" />
        </div>
      </div>
    </div>
  );

  const renderData = () => {
    return (
      <form
        onSubmit={isCover ? onSubmitCover : onSubmit}
        className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
      >
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            onClick={clearOnClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
            <Dialog.Title
              as="h3"
              className="text-2xl leading-6 font-medium text-gray-700"
            >
              Carica media
            </Dialog.Title>

            {isError ? <div>An error occurred: {error?.message}</div> : null}
            {isErrorCover ? <div>An error occurred: {errorCover?.message}</div> : null}

            <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              <div className="sm:col-span-3">
                <div className="grid grid-cols-1 space-y-2">
                  <h3 className="block text-md font-medium text-gray-700 tracking-wide">
                    Seleziona file
                  </h3>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="images"
                      className="overflow-auto hover:overflow-scroll rounded-lg border-4 border-dashed w-full h-80 sm:h-60 sm:p-10 p-5 group text-center"
                    >
                      {/* <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10"> */}
                      {/* <img
                                  className="has-mask h-36 object-center"
                                  src="https://img.freepik.com/free-vector/image-upload-concept-landing-page_52683-27130.jpg?size=338&ext=jpg"
                                  alt="freepik image"
                                /> */}
                      {(mediaList.length && (
                        <div className="grid grid-cols-3 gap-y-3 gap-x-3 sm:grid-cols-3 ">
                          {mediaList.map(renderMediaPreview)}
                        </div>
                      )) || (
                        <div className="h-full w-full text-center flex flex-col items-center justify-center">
                          <UploadIcon className="h-20 w-20 object-center text-pink-200" />
                          {/* </div> */}
                          <p className="pointer-none text-gray-500 ">
                            {/* <span className="text-sm">Trascina</span> qui i tuoi
                                  media
                                  <br /> oppure clicca qui per selezionarli dal tuo */}
                            Clicca qui per selezionarli dal tuo dispositivo
                          </p>
                        </div>
                      )}
                      <input
                        type="file"
                        id="images"
                        multiple
                        className="hidden"
                        onChange={handleOnChangeInput}
                      />
                    </label>
                  </div>
                </div>
                <p className="text-sm text-gray-300 mt-3">
                  <span>
                    Formati supportati: jpeg, jpg, png, mp4, mov, avi - Dimensione
                    massima: 5GB
                  </span>
                </p>
                {/* {errors.files && (
                        <p className="text-sm text-red mt-3">{errors.files[0].message}</p>
                      )} */}
              </div>
              {/* <div>{files.length > 0 && <img src={files[0]} alt="nada" />}</div> */}
            </div>
          </div>
        </div>
        <div className="mt-8 sm:mt-8 sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Salva
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={clearOnClose}
          >
            Annulla
          </button>
        </div>
      </form>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {isLoadingDO || isLoading || isLoadingCover
                ? renderLoader()
                : isError || isErrorCover
                ? renderError()
                : renderData()}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
