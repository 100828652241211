import { useCallback, useState } from 'react';

import { compress, EImageType } from 'image-conversion';
import { UploadIcon } from '@heroicons/react/outline';

import { useAuth, useModal, useToast } from 'hooks';
import { ICreator, IModal, IMedia, IMedia2 } from 'interfaces';
import { BasicEmptyState } from 'components/common/empty';
import { ProfilePhotoUploadModal } from './ProfilePhotoUploadModal';
import { ProfilePhotoCard } from './ProfilePhotoCard';
import { MediaModal } from 'components/common/media/MediaModal';
import { UserImage } from 'components/common/image';
import { useUploadUserImage } from 'api/user';

interface ProfilePhotosProps {
  data: ICreator;
}

const ProfilePhotosForm = (props: ProfilePhotosProps) => {
  const {
    data: { profilePhotos },
  } = props;

  const { user, setUser } = useAuth();
  const { upload } = useUploadUserImage();

  const [mediaFormModal, setMediaFormModal] = useState<IModal<IMedia>>({
    open: false,
  });

  const uploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length || !user) return;

    const fileBlob = e.target.files[0];
    const fileSizeMb = fileBlob.size / 1024 / 1024; // in MiB

    if (fileSizeMb > 5) {
      alert('Il tuo file è ' + fileSizeMb.toFixed(2) + 'Mb il limite massimo è 5Mb');
      return;
    }

    // Compress image
    const fileBlobCompressed = await compress(fileBlob, {
      quality: 0.8,
      type: EImageType.JPEG,
    });

    var formData = new FormData();
    formData.append('profile', fileBlobCompressed);

    try {
      const { image } = await upload(formData);
      setUser({ ...user, image });

      onSuccess();
    } catch (error) {
      console.error(error);
      onError();
    }
  };

  const profilePhotosMapper = profilePhotos
    ? profilePhotos.map((photo: any) => ({
        id: photo.id,
        url: photo.fileStorage.url,
        original: photo.fileStorage.url,
        isVideo: false,
      }))
    : [];

  const { toastError, toastSuccess } = useToast();

  const onSuccess = () => {
    mediaUploadModalClose();
    toastSuccess({
      title: 'Operazione completata',
      message: 'Foto salvate correttamente',
    });
  };

  const onError = () => {
    mediaUploadModalClose();
    toastError({
      title: 'Errore generico',
      message: "Impossibile salvare le foto. Contattare l'assistenza.",
    });
  };

  const mediaUploadModalOpen = () => {
    setMediaFormModal({ open: true });
  };

  const mediaUploadModalClose = () => {
    setMediaFormModal({ open: false });
  };

  const {
    openModal: viewMediaModalOpen,
    closeModal: viewMediaModalClose,
    modalData: viewMediaModal,
    setData: viewMediaModalSetData,
  } = useModal<IMedia2>();

  const handleOnClickMedia = useCallback(
    async (media: IMedia2) => {
      await viewMediaModalSetData(media);
      viewMediaModalOpen();
    },
    [viewMediaModalSetData, viewMediaModalOpen]
  );

  // const renderLoader = () => (
  //   <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
  //     <div className="mt-3 text-center sm:mt-5">
  //       <div className="mt-2">
  //         <Loader />
  //       </div>
  //     </div>
  //   </div>
  // );

  // const renderError = () => (
  //   <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
  //     <div className="mt-3 text-center sm:mt-5">
  //       <h3 className="text-lg leading-6 font-medium text-gray-900">ERRORE</h3>
  //       <div className="mt-2">
  //         <AlertError message="Errore generico" />
  //       </div>
  //     </div>
  //   </div>
  // );

  const renderData = () =>
    profilePhotosMapper && profilePhotosMapper.length ? (
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 lg:grid-cols-5 xl:gap-x-4">
        {profilePhotosMapper.map((media: any) => (
          <ProfilePhotoCard
            key={media.id}
            data={media}
            onClick={handleOnClickMedia}
            // onDelete={hasDelete ? handleOnDeleteMedia : undefined}
            // hasBadgeApprovation={hasBadgeApprovation}
          />
        ))}
      </div>
    ) : (
      <BasicEmptyState
        title="Nessuna foto profilo"
        descr="Non presenti foto profilo. Aggiungi nuove foto."
      />
    );

  return (
    <>
      <ProfilePhotoUploadModal
        {...mediaFormModal}
        onSuccess={onSuccess}
        onClose={mediaUploadModalClose}
        onError={onError}
      />

      <MediaModal
        {...viewMediaModal}
        mediaArray={profilePhotosMapper}
        onClose={viewMediaModalClose}
        isProfilePhotos={true}
      />

      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div className="border-b">
            <div className="col-span-1 sm:col-span-3 mb-8">
              <label
                htmlFor="photo"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Immagine principale
              </label>
              <div className="mt-4 flex items-center ">
                <span className="h-28 w-28 rounded-lg overflow-hidden bg-gray-100">
                  <UserImage
                    image={user?.image ?? null}
                    className="h-full w-full text-gray-300"
                    alt={user?.username ?? ''}
                  />
                </span>
                <label className="cursor-pointer ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                  Modifica
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={uploadPhoto}
                  />
                </label>
              </div>
            </div>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Foto profilo aggiuntive
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Inserisci le tue foto profilo (max 10). L'immagine principale non cambierà.
            </p>
          </div>
          {renderData()}
        </div>
        {profilePhotosMapper && profilePhotosMapper.length < 10 ? (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              onClick={mediaUploadModalOpen}
            >
              <UploadIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
              Aggiungi
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ProfilePhotosForm;
