// std
import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useRef,
} from 'react';

// 3p
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import {
  Call,
  CallAgent,
  CallClient,
  CallState,
  DeviceManager,
  Features,
  IncomingCall,
  LocalVideoStream,
  RemoteParticipant,
  RemoteVideoStream,
  VideoStreamRenderer,
  VideoStreamRendererView,
} from '@azure/communication-calling';

import { useNavigate } from 'react-router-dom';

// app
import { useCommunicationToken } from './useCommunicationToken';
import { useIncomingCall } from 'components/common/chat/video';
import { useAuth } from 'hooks/useAuth';

type IVideoCallContext = {
  callStatus: CallState;
  startVideoButton: () => void;
  stopVideoButton: () => void;
  startCall: (communicationUserId: string) => void;
  endCall: () => Promise<void>;
  localVideoStream: VideoStreamRendererView | undefined;
  remoteVideoStream: VideoStreamRendererView[] | undefined;
  isMuted: boolean;
  startMicrophoneButton: () => void;
  stopMicrophoneButton: () => void;
  switchCamera: () => void;
};

// const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const videoCallContext = createContext<IVideoCallContext>({} as IVideoCallContext);

enum CAMERA_FACING_TYPE {
  FRONT = 'camera2 1, facing front',
  BACK = 'camera2 0, facing back',
}

// Provider component that wraps your app and makes videoCall object ...
// ... available to any child component that calls useVideoCall().
export function VideoCallContextProvider({ children }: { children: JSX.Element }) {
  const videoCall = useProvideVideoCall();
  return (
    <videoCallContext.Provider value={videoCall}>{children}</videoCallContext.Provider>
  );
}

// Hook for child components to get the videoCall object ...
// ... and re-render when it changes.
export const useVideoCall = () => {
  return useContext(videoCallContext);
};

// Provider hook that creates videoCall object and handles state
function useProvideVideoCall() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const { showIncomingCall, closeIncomingCall, showOutcomingCall, closeOutcomingCall } =
    useIncomingCall();

  const { communicationToken } = useCommunicationToken();

  const callClientRef = useRef<CallClient>();
  const callAgentRef = useRef<CallAgent>();
  const callManagerRef = useRef<Call>();

  const deviceManagerRef = useRef<DeviceManager>();
  const localVideoStreamRendererRef = useRef<any>();

  const [localVideoStream, setLocalVideoStream] = useState<VideoStreamRendererView>();
  const [isMuted, setIsMuted] = useState<boolean>(false);

  /**
   * To render a LocalVideoStream, you need to create a new instance of VideoStreamRenderer, and then
   * create a new VideoStreamRendererView instance using the asynchronous createView() method.
   * You may then attach view.target to any UI element.
   */
  const createLocalVideoStream = useCallback(async (cameraIndex: number) => {
    // Set up a camera device to use.
    if (!callClientRef.current) {
      alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
      return;
    }

    if (!deviceManagerRef.current) {
      deviceManagerRef.current = await callClientRef.current.getDeviceManager();
      await deviceManagerRef.current.askDevicePermission({ video: true, audio: true });
    }

    const cameras = await deviceManagerRef.current.getCameras();

    if (cameras) {
      const camera = cameras[cameraIndex];
      return new LocalVideoStream(camera);
    } else {
      console.error(`No camera device found on the system`);
    }
  }, []);

  /**
   * Display your local video stream preview in your UI
   */
  const displayLocalVideoStream = async (localVideoStream: LocalVideoStream) => {
    try {
      const videoStreamRenderer = new VideoStreamRenderer(localVideoStream);
      const view = await videoStreamRenderer.createView();
      setLocalVideoStream(view);

      localVideoStreamRendererRef.current = videoStreamRenderer;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Remove your local video stream preview from your UI
   */
  const removeLocalVideoStream = async () => {
    try {
      localVideoStreamRendererRef.current?.dispose();
      localVideoStreamRendererRef.current = undefined;
      setLocalVideoStream(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  const [remoteVideoStream, setRemoteVideoStream] = useState<VideoStreamRendererView[]>();

  const displayRemoteVideoStream = async (rvs: RemoteVideoStream) => {
    const renderer = new VideoStreamRenderer(rvs);

    let view: VideoStreamRendererView;

    const createView = async () => {
      // Create a renderer view for the remote video stream.
      view = await renderer.createView();
      // Attach the renderer view to the UI.
      setRemoteVideoStream((v = []) => [...v, view]);
    };

    rvs.on('isAvailableChanged', async () => {
      try {
        if (rvs.isAvailable) {
          await createView();
        } else {
          view.dispose();
          setRemoteVideoStream(undefined);
        }
      } catch (e) {
        console.error(e);
      }
    });

    // Remote participant has video on initially.
    if (rvs.isAvailable) {
      try {
        await createView();
      } catch (e) {
        console.error(e);
      }
    }
  };

  /**
   * Subscribe to a remote participant obj.
   * Listen for property changes and collection udpates.
   */
  const subscribeToRemoteParticipant = (remoteParticipant: RemoteParticipant) => {
    try {
      // Inspect the initial remoteParticipant.state value.
      console.log(`Remote participant state: ${remoteParticipant.state}`);
      // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
      remoteParticipant.on('stateChanged', () => {
        console.log(`Remote participant state changed: ${remoteParticipant.state}`);
      });

      // Inspect the remoteParticipants's current videoStreams and subscribe to them.
      remoteParticipant.videoStreams.forEach((rvs) => {
        displayRemoteVideoStream(rvs);
      });

      // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
      // notified when the remoteParticiapant adds new videoStreams and removes video streams.
      remoteParticipant.on('videoStreamsUpdated', (e) => {
        // Subscribe to new remote participant's video streams that were added.
        e.added.forEach((rvs) => {
          displayRemoteVideoStream(rvs);
        });
        // Unsubscribe from remote participant's video streams that were removed.
        e.removed.forEach((remoteVideoStream) => {
          console.log('Remote participant video stream was removed.');
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const endCall = useCallback(async () => {
    if (!callManagerRef.current) {
      alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
      return;
    }

    try {
      await callManagerRef.current.hangUp();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const [callStatus, setCallStatus] = useState<CallState>('None');

  /**
   * Subscribe to a call obj.
   * Listen for property changes and collection updates.
   */
  const subscribeToCall = useCallback(
    (call: Call) => {
      setIsMuted(call.isMuted);

      try {
        // Inspect the initial call.id value.
        console.log(`Call Id: ${call.id}`);
        //Subscribe to call's 'idChanged' event for value changes.
        call.on('idChanged', () => {
          console.log(`Call Id changed: ${call.id}`);
        });

        call.on('isMutedChanged', () => {
          setIsMuted(call.isMuted);
        });

        // Subscribe to call's 'stateChanged' event for value changes.
        call.on('stateChanged', async () => {
          console.log(`Call state changed: ${call.state}`);
          setCallStatus(call.state);

          if (call.state === 'Ringing') {
            // Show modal
            showOutcomingCall(endCall);
          } else if (call.state === 'Connected') {
            // Se connesso lo mando alla pagina della chat
            closeOutcomingCall();

            navigate('/app/call/' + call.id);

            // connectedLabel.hidden = false;
            // acceptCallButton.disabled = true;
            // startCallButton.disabled = true;
            // hangUpCallButton.disabled = false;
            // startVideoButton.disabled = false;
            // stopVideoButton.disabled = false;
            // remoteVideosGallery.hidden = false;
          } else if (call.state === 'Disconnected') {
            // connectedLabel.hidden = true;
            // startCallButton.disabled = false;
            // hangUpCallButton.disabled = true;
            // startVideoButton.disabled = true;
            // stopVideoButton.disabled = true;
            console.log(
              `Call ended, call end reason={code=${call.callEndReason?.code}, subCode=${call.callEndReason?.subCode}}`
            );

            // La persona ha rifiutato la chiamata
            if (call.callEndReason?.code === 603) {
              closeOutcomingCall();
            }

            // La persona non è disponibile
            if (call.callEndReason?.code === 480) {
              alert('Utente non disponibile');
            }
          }
        });

        call.localVideoStreams.forEach(async (lvs) => {
          await displayLocalVideoStream(lvs);
        });

        call.on('localVideoStreamsUpdated', (e) => {
          e.added.forEach(async (lvs) => {
            await displayLocalVideoStream(lvs);
          });
          e.removed.forEach((lvs) => {
            removeLocalVideoStream();
          });
        });

        // Inspect the call's current remote participants and subscribe to them.
        call.remoteParticipants.forEach((remoteParticipant) => {
          subscribeToRemoteParticipant(remoteParticipant);
        });

        // Subscribe to the call's 'remoteParticipantsUpdated' event to be
        // notified when new participants are added to the call or removed from the call.
        call.on('remoteParticipantsUpdated', (e) => {
          // Subscribe to new remote participants that are added to the call.
          e.added.forEach((remoteParticipant) => {
            subscribeToRemoteParticipant(remoteParticipant);
          });
          // Unsubscribe from participants that are removed from the call
          e.removed.forEach((remoteParticipant) => {
            console.log('Remote participant removed from the call.');
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endCall, subscribeToRemoteParticipant]
  );

  const startCall = useCallback(
    async (communicationUserId: string) => {
      if (!callAgentRef.current) {
        alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
        return;
      }

      try {
        const localVideoStream = await createLocalVideoStream(0);

        const videoOptions = localVideoStream
          ? { localVideoStreams: [localVideoStream] }
          : undefined;

        callManagerRef.current = callAgentRef.current.startCall(
          [{ communicationUserId }],
          {
            videoOptions,
          }
        );

        // Subscribe to the call's properties and events.
        subscribeToCall(callManagerRef.current);
      } catch (error) {
        console.error(error);
        alert(error);
      }
    },
    [createLocalVideoStream, subscribeToCall]
  );

  /**
   * Start your local video stream.
   * This will send your local video stream to remote participants so they can view it.
   */
  const startVideoButton = useCallback(async () => {
    if (!callManagerRef.current) {
      alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
      return;
    }

    try {
      const localVideoStream = await createLocalVideoStream(0);

      if (localVideoStream) {
        await callManagerRef.current.startVideo(localVideoStream);
      }
    } catch (error) {
      console.error(error);
    }
  }, [createLocalVideoStream]);

  /**
   * Stop your local video stream.
   * This will stop your local video stream from being sent to remote participants.
   */
  const stopVideoButton = useCallback(async () => {
    if (!callManagerRef.current) {
      alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
      return;
    }

    try {
      const localVideoStream = callManagerRef.current.localVideoStreams[0];
      await callManagerRef.current.stopVideo(localVideoStream);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const switchCamera = useCallback(async () => {
    const callManager = callManagerRef.current;
    const deviceManager = deviceManagerRef.current;

    if (!callManager || !deviceManager) {
      alert(
        'Questa funzionalità non è disponibile su questo broswers e/o dispositivo. Oppure non hai concesso i permessi per utilizzo della camera'
      );
      return;
    }

    try {
      const currentLocalVideoSource = callManager.localVideoStreams[0].source;

      const cameras = await deviceManager.getCameras();

      // Android ha un CAMERA TYPE
      const currentVideoSourceType = Object.values(CAMERA_FACING_TYPE).find(
        (i) => i === currentLocalVideoSource.name
      );

      if (currentVideoSourceType) {
        const nextCameraType =
          currentVideoSourceType === CAMERA_FACING_TYPE.FRONT
            ? CAMERA_FACING_TYPE.BACK
            : CAMERA_FACING_TYPE.FRONT;

        const newSource = cameras.find((source) => source.name === nextCameraType);

        if (newSource) {
          callManager.localVideoStreams[0].switchSource(newSource);
          return;
        }
      }

      const userAgent = navigator.userAgent;

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        const newSource = cameras.filter(
          (source) => source.id !== currentLocalVideoSource.id
        );

        if (newSource && newSource[0]) {
          callManager.localVideoStreams[0].switchSource(newSource[0]);
          return;
        }
      }

      // Android non ha camera type
      const currentIndex = cameras.findIndex(
        (s) => s.name === currentLocalVideoSource.name
      );
      const nextIndex = (currentIndex + 1) % cameras.length || 0;
      const newSource = cameras[nextIndex];

      callManager.localVideoStreams[0].switchSource(newSource);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const startMicrophoneButton = useCallback(() => {
    if (!callManagerRef.current) {
      alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
      return;
    }

    callManagerRef.current.unmute();
  }, []);

  const stopMicrophoneButton = useCallback(() => {
    if (!callManagerRef.current) {
      alert('Questa funzionalità non è disponibile su questo broswers e/o dispositivo');
      return;
    }

    callManagerRef.current.mute();
  }, []);

  const handleIncomingCall = useCallback(
    async ({ incomingCall }: { incomingCall: IncomingCall }) => {
      // Se il chiamante annulla la chiamata
      incomingCall.on('callEnded', () => {
        // console.log(args.callEndReason);
        closeIncomingCall();
      });

      const accept = async () => {
        callManagerRef.current = await incomingCall.accept();
        subscribeToCall(callManagerRef.current);
      };

      const reject = () => {
        incomingCall.reject();
        callManagerRef.current = undefined;
      };

      const {
        callerInfo: { displayName },
      } = incomingCall;

      showIncomingCall(accept, reject, displayName);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscribeToCall]
  );

  const [envInfo, setEnvInfo] = useState<any>();

  // Inizializate videoCall client
  useEffect(() => {
    if (!communicationToken) {
      return;
    }

    (async function () {
      try {
        console.log('callAgent init');

        const { username } = user!;
        const { token } = communicationToken;

        const callClient = new CallClient();

        const environmentInfo = await callClient
          .feature(Features.DebugInfo)
          .getEnvironmentInfo();
        setEnvInfo(environmentInfo);

        const callAgent = await callClient.createCallAgent(
          new AzureCommunicationTokenCredential(token),
          { displayName: username }
        );

        callAgent.on('incomingCall', handleIncomingCall);

        // Listen for an incoming call to accept.
        callClientRef.current = callClient;
        callAgentRef.current = callAgent;
      } catch (ex) {
        console.error(ex);
      }
    })();

    return () => {
      if (callAgentRef.current) {
        console.log('CallAgent dispose');
        callAgentRef.current.off('incomingCall', handleIncomingCall);
        callAgentRef.current.dispose();
        callAgentRef.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationToken]);

  // Return the user object and videoCall methods
  return Object.freeze({
    callStatus,
    startCall,
    endCall,
    stopVideoButton,
    startVideoButton,
    startMicrophoneButton,
    stopMicrophoneButton,
    switchCamera,
    localVideoStream,
    remoteVideoStream,
    isMuted,
    envInfo,
  });
}
