// std
import { useCallback } from 'react';

// 3p
import { Outlet, useParams } from 'react-router-dom';

// app
import { ChatThreadList } from 'components/common/chat';
import { useGetAllThread } from 'api/chat';
// import { useVideoCall } from 'hooks';

export const Chat = () => {
  const { chatId } = useParams();

  // const { envInfo } = useVideoCall();

  const sidebarStyle = chatId ? 'hidden sm:block' : '';

  const { getAllThreadQuery } = useGetAllThread();

  const onItemClick = useCallback((id: string) => {
    return `/app/chat/${id}`;
  }, []);

  return (
    <>
      <main className="flex-grow flex flex-row min-h-0 bg-white">
        <section
          className={`flex flex-col overflow-auto w-full sm:w-24 lg:max-w-sm md:w-2/5 ${sidebarStyle}`}
        >
          {/* <ChatThreadSearch /> */}
          <ChatThreadList query={getAllThreadQuery} onItemClick={onItemClick} />
        </section>

        <section className="flex flex-col flex-auto">
          <Outlet />
        </section>
      </main>
    </>
  );
};
