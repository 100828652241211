import { useCallback, useState } from 'react';

// 3p
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { CashIcon, VideoCameraIcon } from '@heroicons/react/outline';

// app
import { BadgeTypeEnum, ROLES } from 'config';
import { IChatThread, IModal, IPayInTipForm } from 'interfaces';

import { formatDateDistance } from 'utils';
import { useAuth, useToast, useVideoCall } from 'hooks';

import { UserImage } from '../image';
import { TipBuyModal } from '../payment';
import { BadgeCheckIcon } from '@heroicons/react/solid';

interface IChatAreaHeaderProps {
  thread: IChatThread;
}

export const ChatAreaHeader = (props: IChatAreaHeaderProps) => {
  const { thread } = props;

  // const {
  //   receiver: { user: receiverUser },
  // } = thread;

  // const userUsername = receiverUser.username;
  // const usercommunicationUserId = receiverUser.communicationUserId;
  // const userImageUrl = receiverUser.image?.url ?? '';
  // const userIsOnline = receiverUser.lastAccess.isOnline;
  // const userLastAccessAt = formatDateDistance(receiverUser.lastAccess.lastActivityAt);

  const { participants } = thread;

  const names = participants.map(({ user }) => user.username).join(', ');

  const images = participants.reduce((prev, { user }) => {
    if (user.image) {
      return prev.concat([user.image.url]);
    }
    return prev;
  }, [] as string[]);

  let isUserOnline: boolean = false;
  let userNameTip: string | undefined;
  let userLastAccessAt: string | undefined;
  let usercommunicationUserId: string | undefined;

  if (participants && participants.length === 1) {
    isUserOnline = participants[0].user.lastAccess.isOnline;
    userNameTip = participants[0].user.username;
    usercommunicationUserId = participants[0].user.communicationUserId;

    const { distance, seconds } = formatDateDistance(
      participants[0].user.lastAccess.lastActivityAt
    );
    userLastAccessAt = seconds > 300 ? distance : 'Online';
  }

  const [tipBuyModal, setTipBuyModal] = useState<IModal<IPayInTipForm>>({
    open: false,
  });

  const navigate = useNavigate();

  const { user } = useAuth();

  const { toastError } = useToast();

  const tipBuyOpen = () => {
    const { role } = user!;
    if (role.code === ROLES.CREATOR) {
      alert('Questa opzione può essere utilizzata dai clienti per pagarti una tip');
      return;
    }
    setTipBuyModal({ open: true });
  };

  const renderParticipants = () => {
    return participants.map(({ user }, i) => {
      return (
        <>
          <p className="font-bold">{user.username}</p>
          {user.badge &&
            user.badge.code &&
            user.badge.code === BadgeTypeEnum.PAYING_CUSTOMER && (
              <BadgeCheckIcon
                className="h-4 w-4 ml-0.5"
                aria-hidden="true"
                color="#3897f0"
              />
            )}
          {i === 0 && participants.length > 1 && <span className="mr-0.5">,</span>}
        </>
      );
    });
  };

  const tipBuyClose = () => {
    setTipBuyModal({ open: false });
  };

  const tipError = (error: AxiosError<string, any>) => {
    toastError({
      title: 'Impossibile inviare tip',
      message: error.response?.data,
    });
  };

  const { startCall } = useVideoCall();

  const handleOnStartCall = useCallback(() => {
    const { role } = user!;

    if (role.code !== ROLES.CREATOR) {
      alert(
        'Solo i creator possono videochiamarti, una volta effettuato il pagamento sarà il creator a mandarti la richiesta di videochiamata'
      );
      return;
    }

    if (usercommunicationUserId) {
      startCall(usercommunicationUserId);
    }
  }, [usercommunicationUserId, startCall, user]);

  return (
    <div className="chat-header pl-2 pr-6 sm:px-6 pt-4 shadow">
      <div className="flex flex-row flex-none justify-between pb-3 items-center border-b-2 border-black-500">
        <div className="flex justify-center items-center">
          <div
            className="mx-2 h-6 w-6 relative flex flex-shrink-0 cursor-pointer sm:hidden"
            onClick={() => {
              navigate(-1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <div className="w-12 h-12 mr-3 relative flex flex-shrink-0">
            {images && (
              <UserImage
                alt={names}
                params={{ class: 'small' }}
                image={{ type: 'image', url: images[0] }}
              />
            )}
          </div>
          <div className="text-sm">
            <div className="flex">{renderParticipants()}</div>
            <p>{isUserOnline ? 'Online' : userLastAccessAt}</p>
          </div>
        </div>
        <div className="flex">
          <button
            type="button"
            className="block rounded-full text-pink-600 hover:text-pink-700 p-2 ml-4"
            onClick={handleOnStartCall}
          >
            <VideoCameraIcon className="h-8 w-8" aria-hidden="true" />
          </button>
          {userNameTip &&
            (user!.role.code === ROLES.CUSTOMER || user!.role.code === ROLES.CREATOR) && (
              <>
                <TipBuyModal
                  {...tipBuyModal}
                  username={userNameTip}
                  setOpen={setTipBuyModal}
                  onClose={tipBuyClose}
                  onSuccess={tipBuyClose}
                  onError={tipError}
                />
                <button
                  type="button"
                  className="block rounded-full text-pink-600 hover:text-pink-700 p-2 ml-4"
                  onClick={tipBuyOpen}
                >
                  <CashIcon className="h-8 w-8" aria-hidden="true" />
                </button>
              </>
            )}
        </div>
      </div>
      <div className="px-3 py-3">
        <p className="text-xs leading-relaxed	font-medium">
          ⛔️ È ASSOLUTAMENTE VIETATO chiedere o fornire contatti esterni (Telegram,
          Instagram, Whatsapp ecc.)
        </p>
        <p className="text-xs leading-relaxed font-medium">
          ⚠️ VIDEOCHIAMATE: in caso di problemi di visualizzazione/audio consigliamo di
          utilizzare l'ultima versione di CHROME (o SAFARI per iOS)
        </p>
        <p className="text-xs leading-relaxed font-medium">
          <a href="/faq" title="Visita le Faq" className="text-indigo-600 underline">
            Visita la Faq per tutte le info
          </a>
        </p>
      </div>
    </div>
  );
};
