// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import { IResponseGetAllAdminMedia, IResponseWithCursor } from 'interfaces';

import APIClient from '../../ApiClient';

const getAllMedia = async (params: any) => {
  const { queryKey } = params;
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IResponseGetAllAdminMedia>>(
    key,
    {
      params: {
        ...searchQuery,
        ...pagingQuery,
      },
    }
  );
  return data;
};

export function useGetAllMedia(searchQuery: any, pagingQuery: any) {
  const getAllMediaQuery = useQuery<
    IResponseWithCursor<IResponseGetAllAdminMedia>,
    AxiosError<string, any>
  >([ENV_ADMIN.GET_ALL_MEDIA, searchQuery, pagingQuery], getAllMedia, {
    keepPreviousData: true,
  });

  return {
    getAllMediaQuery,
  };
}
