import { IAttachmentChatVideo, IImageGalleryItem, IMediaVideo } from 'interfaces';

interface MediaModalVideoProps {
  video: IMediaVideo | IImageGalleryItem | IAttachmentChatVideo;
}

export const MediaModalVideo = (props: MediaModalVideoProps) => {
  const { video } = props;
  const { libraryId, bunnyId, url } = video as any;

  return libraryId && bunnyId ? (
    <div className="aspect-w-16 aspect-h-9">
      <iframe
        title="title"
        src={`https://iframe.mediadelivery.net/embed/${libraryId}/${bunnyId}`}
        loading="lazy"
        style={{
          height: '100%',
          width: '100%',
        }}
        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
      />
    </div>
  ) : (
    <div className="flex bg-black aspect-w-16 aspect-h-9 mx-auto relative flex-shrink-0 max-w-xs lg:max-w-md">
      <video
        width="100%"
        height="100%"
        autoPlay
        loop
        controls
        controlsList="play nodownload"
      >
        <source src={`${url}#t=0.001`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
