import CryptoJS from 'crypto-js';

export function fromByteToMb(sizeInBytes: number): number {
  return sizeInBytes / (1024 * 1024);
}

export function fromMbToGb(sizeInMb: number): number {
  return sizeInMb / 1024;
}

/**
 * Return the duration in seconds of the video
 * @param file
 * @returns
 */
export const getVideoDuration = (file: File) => {
  return new Promise<number>((resolve) => {
    const video = document.createElement('video');

    // this is important
    video.autoplay = true;
    video.muted = true;

    const videoUrl = URL.createObjectURL(file);
    video.src = videoUrl;

    video.onloadeddata = () => {
      video.pause();

      const durationSeconds = Math.floor(video.duration);

      URL.revokeObjectURL(videoUrl);

      return resolve(durationSeconds);
    };
  });
};

/**
 * Generate a jpeg preview of the video
 * @param file
 * @returns
 */
export const generateVideoThumbnail = (file: File) => {
  return new Promise<string>((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    // this is important
    video.autoplay = true;
    video.muted = true;

    const videoUrl = URL.createObjectURL(file);
    video.src = videoUrl;

    video.onloadeddata = () => {
      const ctx = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      video.pause();

      const base64 = canvas.toDataURL('image/jpeg', 0.8);

      URL.revokeObjectURL(videoUrl);

      return resolve(base64);
    };
  });
};

/**
 * Questa funzione va in errore su alcuni tipi di file.
 * Abbiamo deciso di deprecarla poichè la generazione dell'MD5 è una funzionalità non implementata
 * @param file
 * @returns
 */
export async function calculateMD5(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;

      const md5 = CryptoJS.MD5(
        CryptoJS.enc.Latin1.parse(new TextDecoder().decode(arrayBuffer))
      ).toString();

      resolve(md5);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function getFileType(file: File) {
  return file.type.split('/')[0];
}

export function generatePreviewURL(url: string) {
  const urlObj = new URL(url);
  urlObj.searchParams.append('width', '280');
  urlObj.searchParams.append('quality', '50');
  return urlObj.toString();
}

export function generateOriginalURL(url: string) {
  const urlObj = new URL(url);
  urlObj.searchParams.append('width', '720')
  urlObj.searchParams.append('quality', '80');
  urlObj.searchParams.append('aspect_ratio', '1:1');
  return urlObj.toString();
}
