/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useCallback, useMemo, useRef } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

// app
import { IAlbum, IMedia2 } from 'interfaces';

import { useDeleteMedia } from 'api/media/useDeleteMedia';
import { ContentLoading } from 'components/common/payment';

export interface IMediaDeleteModalData {
  media: IMedia2;
  album: IAlbum;
}

interface IMediaDeleteModalProps {
  data?: IMediaDeleteModalData;
  open: boolean;
  onSuccess?: (data: IMedia2) => void;
  onError?: (error: unknown) => void;
  onClose: () => void;
}

export function MediaDeleteModal(props: IMediaDeleteModalProps) {
  const { open, onSuccess, onClose, onError, data } = props;

  const media = useMemo(() => data?.media, [data]);
  const album = useMemo(() => data?.album, [data]);

  const cancelButtonRef = useRef(null);

  const { deleteMediaMutation } = useDeleteMedia(album);
  const { mutateAsync: mutateDeleteMedia, isLoading } = deleteMediaMutation;

  const handleDeleteMedia = useCallback(async () => {
    if (!media) return;

    try {
      await mutateDeleteMedia(media);
      if (onSuccess) onSuccess(media);
    } catch (error) {
      console.log(error);
      if (onError) onError(error);
    }
  }, [media, onSuccess, onError, mutateDeleteMedia]);

  const renderContent = useMemo(
    () => (
      <>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Eliminazione media
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Rimuovere definitivamente questo media?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleDeleteMedia}
          >
            Elimina
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
            ref={cancelButtonRef}
          >
            Annulla
          </button>
        </div>
      </>
    ),
    [handleDeleteMedia, onClose]
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {isLoading ? <ContentLoading /> : renderContent}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
