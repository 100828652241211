import { Fragment, useMemo } from 'react';

// 3p
import { Link } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon, XIcon } from '@heroicons/react/outline';

// app
import styles from 'styles';

import { UserImage } from 'components/common/image';
import Loader from 'components/common/Loader';

import { useGetAllCouponCustomer } from 'api/admin/coupon';

interface ICouponCustomerModalProps {
  data?: any;
  open: boolean;
  onClose: () => void;
}

export const CouponCustomerModal = (props: ICouponCustomerModalProps) => {
  const { data, open, onClose } = props;

  const couponId = useMemo(() => data?.couponId, [data]);

  const { getAllCouponCustomerQuery } = useGetAllCouponCustomer(couponId);
  const { data: getallCouponCustomerData, isLoading: getallCouponCustomerIsLoading } =
    getAllCouponCustomerQuery;

  const customerList = useMemo(
    () => getallCouponCustomerData?.data ?? [],
    [getallCouponCustomerData]
  );

  const renderLoader = () => (
    <div className="m-3 sm:m-5 text-center">
      <Loader />
    </div>
  );

  const renderEmpty = () => (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">Questo codice non è stato utilizzato</p>
        </div>
      </div>
    </div>
  );

  const renderData = () => (
    <>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className={styles.modal.title}>
            Clienti
          </Dialog.Title>

          <div className="mt-5">
            {customerList.length === 0 && renderEmpty()}
            <ul className="divide-y divide-gray-200">
              {customerList.map(({ user }) => (
                <li key={user.id} className="py-4">
                  <Link to={`/admin/users/customers/${user.id}`}>
                    <div className="flex">
                      <div className="mr-4 flex-shrink-0 self-center max-w-xs	">
                        <UserImage
                          image={user.image}
                          alt={user.username!}
                          className="h-12 w-12 rounded-full"
                        />
                      </div>
                      <div className="self-center">
                        <h4 className="text-lg font-bold m-0 p-0">{user.username}</h4>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button type="button" className={styles.modal.button2} onClick={onClose}>
          Chiudi
        </button>
      </div>
    </>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {getallCouponCustomerIsLoading ? renderLoader() : renderData()}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
