import { useEffect, useRef, useState } from 'react';

// app
import { IChatMessage } from 'interfaces';

// type ChatScrollPosition = 'bottom' | 'top' | 'manual';

type ChatBoxProps = {
  messages: IChatMessage[];
  onFetchNewMessages: () => Promise<void>;
};

export const useAutoScroll = (options: ChatBoxProps) => {
  const { messages, onFetchNewMessages } = options;

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [isScrolledToTop, setIsScrolledToTop] = useState(false);

  const chatBoxRef = useRef<HTMLDivElement>(null);
  const prevChatBoxRef = useRef<any>(null);

  useEffect(() => {
    const chatBox = chatBoxRef.current;

    if (chatBox && isScrolledToBottom) {
      setTimeout(() => {
        chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
        setIsScrolledToBottom(true);
      }, 1);
    }
  }, [isScrolledToBottom, messages]);

  useEffect(() => {
    const chatBox = chatBoxRef.current;
    const prevChatBox = prevChatBoxRef.current;

    if (
      chatBox &&
      prevChatBox &&
      isScrolledToTop &&
      chatBox.scrollHeight > prevChatBox.scrollHeight
    ) {
      setTimeout(() => {
        const previousScrollHeight = prevChatBox.scrollHeight;
        const previousScrollTop = prevChatBox.scrollTop;

        const newScrollHeight = chatBox.scrollHeight - previousScrollHeight;
        chatBox.scrollTop = previousScrollTop + newScrollHeight;

        setIsScrolledToTop(false);
      }, 1);
    }
  }, [isScrolledToTop, messages]);

  const handleChatBoxScroll = async () => {
    const chatBox = chatBoxRef.current;

    if (!chatBox) return;

    const { scrollTop, scrollHeight, clientHeight } = chatBox;
    const scrollPosition = Math.round(scrollHeight - scrollTop);
    const isAtBottom = scrollPosition - 113 < clientHeight;

    setIsScrolledToBottom(isAtBottom);
    // console.log(`Scroll Height ${scrollPosition} ClientHeight ${clientHeight}`);

    // Scroll at top
    if (scrollTop === 0) {
      prevChatBoxRef.current = { scrollHeight, scrollTop };
      await onFetchNewMessages();
      setIsScrolledToTop(true);
    }
  };

  return {
    chatBoxRef,
    handleChatBoxScroll,
  };
};
