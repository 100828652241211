// 3p
import axios, { AxiosRequestConfig } from 'axios';

// app
import APIClient from '../ApiClient';

export async function createUploadUrl(file: File) {
  const body = {
    fileType: file.type,
  };

  const { data } = await APIClient.post<any>('/communications/createUploadUrl', body);

  return data;
}

export async function uploadFile(
  signedUrl: string,
  file: File,
  customOptions: AxiosRequestConfig<File>
) {
  const options = {
    headers: {
      'Content-Type': file.type,
      //'x-amz-acl': 'public-read',
    },
    ...customOptions,
  };

  await axios.put(signedUrl, file, options);
}

export function useAttachment() {
  return { createUploadUrl, uploadFile };
}
