import { Fragment, useEffect, useMemo } from 'react';
import axios from 'axios';

// 3p
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { InformationCircleIcon, XIcon } from '@heroicons/react/outline';

// app
import styles from 'styles';
import { ICoupon } from 'interfaces';
import { classNames, convertFromCent } from 'utils';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';

import { useCouponSave } from 'api/admin/coupon';
import HttpStatusCode from 'utils/HttpStatusCode';

interface ICouponFormModal {
  code: string;
  description: string;
  amount: number;
  isUnlimited: boolean;
  maxNumOfUsage: number | null;
  isEnable: boolean;
}

interface ICouponFormModalProps {
  data?: ICoupon;
  open: boolean;
  onSuccess?: (data: ICoupon) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const CouponFormModal = (props: ICouponFormModalProps) => {
  const { data, open, onSuccess, onError, onClose } = props;

  const { useCouponSaveMutation } = useCouponSave(data?.id);
  const { mutateAsync, isLoading } = useCouponSaveMutation;

  const {
    control,
    handleSubmit,
    reset,
    register,
    setError,
    formState: { errors },
    watch,
  } = useForm<ICouponFormModal>();

  // Set Default data or clean
  useEffect(() => {
    if (!open) return;

    if (data) {
      const { amount, maxNumOfUsage } = data;

      reset({
        ...data,
        amount: convertFromCent(amount),
        isUnlimited: maxNumOfUsage === null,
      });
    } else {
      reset({});
    }
  }, [open, data, reset]);

  const onSubmit: SubmitHandler<ICouponFormModal> = async (formData) => {
    // const { isUnlimited, ...data } = formData;

    try {
      const coupon = await mutateAsync(formData);
      if (onSuccess) onSuccess(coupon);
      reset();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        if (response?.status === HttpStatusCode.CONFLICT) {
          setError('code', {
            type: 'server',
            message: 'Il codice inserito è già stato utilizzato',
          });
        }
      }

      if (onError) onError(error);
    }
  };

  const renderLoader = () => (
    <div className="m-3 sm:m-5 text-center">
      <Loader />
    </div>
  );

  const isUnlimited = watch('isUnlimited');
  const isEditMode = useMemo(() => data !== undefined, [data]);

  const renderData = () => (
    <>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
            <Dialog.Title as="h3" className={styles.modal.title}>
              {isEditMode ? 'Modifica coupon' : 'Nuovo coupon'}
            </Dialog.Title>
            <div className="mt-5 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              {isEditMode && (
                <div className=" sm:col-span-3 rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-blue-700">
                        In modalità modifica è possibile solo attivare o disattivare il
                        coupon
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {errors.code && (
                <div className="sm:col-span-3">
                  <AlertError message={errors.code.message} />
                </div>
              )}
              <div className="sm:col-span-3">
                <label htmlFor="code" className={styles.input.label}>
                  Codice
                </label>
                <div className={styles.input.containerRelative}>
                  <input
                    type="text"
                    id="code"
                    maxLength={50}
                    className={`uppercase ${styles.input.base} ${
                      isEditMode ? styles.input.disabled : ''
                    }`}
                    {...register('code', { required: true, maxLength: 50 })}
                    disabled={isEditMode}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="description" className={styles.input.label}>
                  Descrizione
                </label>
                <div className={styles.input.containerRelative}>
                  <textarea
                    id="description"
                    maxLength={500}
                    className={`shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-lg border-gray-300 rounded-md ${
                      isEditMode ? styles.input.disabled : ''
                    }`}
                    {...register('description', { required: false, maxLength: 500 })}
                    disabled={isEditMode}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="amount" className={styles.input.label}>
                  Valore
                </label>
                <div className={styles.input.containerRelative}>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-lg">€</span>
                  </div>
                  <input
                    autoComplete="off"
                    type="number"
                    id="amount"
                    className={`pl-7 ${styles.input.base} ${
                      isEditMode ? styles.input.disabled : ''
                    }`}
                    placeholder="3.00"
                    min="3"
                    max="400"
                    step="any"
                    {...register('amount', {
                      valueAsNumber: true,
                    })}
                    disabled={isEditMode}
                  />
                </div>
              </div>

              <div className="sm:col-span-3 mt-3">
                <Switch.Group as="div" className="flex items-center justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label as="span" className={styles.input.label} passive>
                      Utilizzi illimitati
                    </Switch.Label>
                    <Switch.Description as="span" className="text-sm text-gray-500">
                      Attiva questa opzione per disabilitare il numero di utilizzi
                    </Switch.Description>
                  </span>

                  <Controller
                    name="isUnlimited"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        className={classNames(
                          value && !isEditMode ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                        disabled={isEditMode}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
              </div>

              {!isUnlimited && (
                <div className="sm:col-span-3">
                  <label htmlFor="maxNumOfUsage" className={styles.input.label}>
                    Numero di utilizzi
                  </label>
                  <div className={styles.input.containerRelative}>
                    <input
                      autoComplete="off"
                      type="number"
                      id="maxNumOfUsage"
                      className={`${styles.input.base} ${
                        isEditMode ? styles.input.disabled : ''
                      }`}
                      min="1"
                      max="10000"
                      step="1"
                      {...register('maxNumOfUsage', {
                        valueAsNumber: true,
                      })}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              )}

              <div className="sm:col-span-3">
                <Switch.Group as="div" className="flex items-center justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label
                      as="span"
                      className="text-md font-medium text-gray-900"
                      passive
                    >
                      Attivo
                    </Switch.Label>
                    <Switch.Description as="span" className="text-sm text-gray-500">
                      Disattiva per non rendere attivo questo codice
                    </Switch.Description>
                  </span>
                  <Controller
                    name="isEnable"
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value === true}
                        onChange={onChange}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:flex sm:flex-row-reverse">
          <button type="submit" className={styles.modal.button1}>
            Salva
          </button>
          <button type="button" className={styles.modal.button2} onClick={onClose}>
            Chiudi
          </button>
        </div>
      </form>
    </>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {isLoading ? renderLoader() : renderData()}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
