/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect } from 'react';

// 3p
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { IProduct, IProductForm } from 'interfaces';
import { useSaveProduct } from 'api/product';
import { classNames } from 'utils';

interface ProductCreateModalProps {
  data?: IProduct;
  open: boolean;
  onSuccess?: (product: IProduct) => void;
  onError?: (error: Error, variables: IProductForm, context: unknown) => void;
  onClose: () => void;
}

export default function ProductCreateModal(props: ProductCreateModalProps) {
  const { open, onSuccess, onError, onClose, data } = props;

  const { saveProductMutation } = useSaveProduct(data?.id);
  const { isLoading, isError, error, mutate } = saveProductMutation;

  const { reset, register, handleSubmit, control } = useForm<IProductForm>();

  const onSubmit: SubmitHandler<IProductForm> = (data) => {
    if (isLoading) return;

    mutate(data, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  // Set Default data or clean
  useEffect(() => {
    if (!open) return;

    if (data) {
      const price = data.price / 100;
      reset({ ...data, price });
    } else {
      reset({});
    }
  }, [open, data, reset]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl leading-6 font-medium text-gray-900"
                  >
                    {data ? 'Modifica servizio' : ' Nuovo servizio'}
                  </Dialog.Title>

                  {isError ? <div>An error occurred: {error?.message}</div> : null}

                  <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="price"
                        className="block text-md font-medium text-gray-700"
                      >
                        Prezzo
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-lg">€</span>
                        </div>
                        <input
                          id="price"
                          className=" sm:text-lg focus:ring-pink-500 focus:border-pink-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                          placeholder="3.00"
                          type="number"
                          min="3"
                          max="400"
                          step="any"
                          {...register('price', {
                            required: true,
                            valueAsNumber: true,
                            max: 400,
                            min: 3,
                          })}
                        />
                        {/* <Controller
                          name="price"
                          control={control}
                          defaultValue={0}
                          render={({ field: { onChange } }) => (
                            <CurrencyInput
                              className=" sm:text-lg focus:ring-pink-500 focus:border-pink-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              prefix=""
                              suffix=""
                              maxLength={5}
                              allowNegativeValue={false}
                              onChange={onChange}
                            />
                          )}
                        /> */}
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="title"
                        className="block text-md font-medium text-gray-700"
                      >
                        Titolo
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          id="title"
                          className="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-lg border-gray-300 rounded-md"
                          {...register('title', { required: true })}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="descr"
                        className="block text-md font-medium text-gray-700"
                      >
                        Descrizione
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <textarea
                          id="descr"
                          className="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-lg border-gray-300 rounded-md"
                          {...register('description', { required: true })}
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <Switch.Group
                        as="div"
                        className="flex items-center justify-between"
                      >
                        <span className="flex-grow flex flex-col">
                          <Switch.Label
                            as="span"
                            className="text-sm font-medium text-gray-900"
                            passive
                          >
                            Attivo
                          </Switch.Label>
                          {/* <Switch.Description as="span" className="text-sm text-gray-500">
                    Scegli se rendere visibile il tuo profilo sulla piattaforma
                  </Switch.Description> */}
                        </span>
                        <Controller
                          name="isEnable"
                          control={control}
                          defaultValue={true}
                          render={({ field: { onChange, value } }) => (
                            <Switch
                              checked={value === true}
                              onChange={onChange}
                              className={classNames(
                                value ? 'bg-pink-600' : 'bg-gray-200',
                                'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  value ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                                )}
                              />
                            </Switch>
                          )}
                        />
                      </Switch.Group>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Salva
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onClose}
                >
                  Annulla
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
