import { Fragment, useCallback, useEffect, useMemo } from 'react';

// app

// 3p
import { IChatMessage, IChatThread, IFile, IMedia2, IUser } from 'interfaces';
import { useAuth, useAutoScroll, useModal } from 'hooks';
import { MediaModal } from '../media/MediaModal';
import { useSaveReadReceipt } from 'api/chat';
import { formatDateDistance } from 'utils';

function messageGroup(messages: IChatMessage[]) {
  return messages.reduce((prev, curr) => {
    const message = { ...curr };

    const { sender, sendAt } = curr;

    if (prev.length === 0) {
      const group = {
        id: message.id,
        sender: sender,
        messages: [message],
        sendAt: sendAt,
      };

      return [group];
    }

    const prevGroup = prev[prev.length - 1];
    const { sender: prevSender } = prevGroup;

    // è lo stesso utente, quindi aggiungo il messaggio
    if (prevSender.communicationUserId === sender.communicationUserId) {
      prevGroup.messages.push(message);
    } else {
      // è un altro utente
      const group = {
        id: message.id,
        sender: sender,
        messages: [message],
        sendAt: sendAt,
      };
      return [...prev, group];
    }

    return prev;
  }, [] as IChatMessageGroup[]);
}

interface IChatMessageGroup {
  id: number;
  sender: Partial<IUser>;
  messages: IChatMessage[];
  sendAt: string;
  onViewImage?: (media: IMedia2) => void;
  onViewVideo?: (video: any) => void;
}

enum MessageDirection {
  OUT,
  IN,
}

const STYLES = {
  [MessageDirection.OUT]: {
    first:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-t-lg rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    center:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    last: 'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-b-lg rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    group: 'flex flex-col items-end justify-center group',
    container: 'flex flex-row justify-end mt-3',
    body: 'messages text-sm text-white grid grid-flow-row gap-2',
  },
  [MessageDirection.IN]: {
    first:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-t-lg rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    center:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    last: 'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-b-lg rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    group: 'flex flex-col items-start justify-center group',
    container: 'flex flex-row justify-start mt-3',
    body: 'messages text-sm text-gray-700 grid grid-flow-row gap-2',
  },
};

interface IChatBodyIncomingGroupProps extends IChatMessageGroup {
  showSenderUsername?: boolean;
}

const ChatBodyIncomingGroup = (props: IChatBodyIncomingGroupProps) => {
  const {
    sender,
    messages,
    onViewImage,
    onViewVideo,
    showSenderUsername = false,
  } = props;

  const { user } = useAuth();

  const direction = useMemo(() => {
    if (user?.communicationUserId === sender.communicationUserId) {
      return MessageDirection.OUT;
    } else {
      return MessageDirection.IN;
    }
  }, [sender, user]);

  const onImageClick = useCallback(
    (photoAttachment: { id?: number; photoFile: Partial<IFile>; uploadAt?: string }) => {
      if (!onViewImage) {
        return;
      }

      if (photoAttachment.id && photoAttachment.photoFile.id) {
        onViewImage({
          id: photoAttachment.id,
          url: photoAttachment.photoFile.url!,
          photo: { id: photoAttachment.photoFile.id },
        });
      }
    },
    [onViewImage]
  );

  const onVideoClick = useCallback(
    (videoAttachment: { id?: number; videoFile: Partial<IFile>; uploadAt?: string }) => {
      if (!onViewVideo) {
        return;
      }

      if (videoAttachment.id && videoAttachment.videoFile.id) {
        onViewVideo({
          id: videoAttachment.id,
          url: videoAttachment.videoFile.url!,
          video: {
            id: videoAttachment.videoFile.id,
            url: videoAttachment.videoFile.url!,
          },
        });
      }
    },
    [onViewVideo]
  );

  return (
    <>
      {showSenderUsername ? (
        <div className="font-bold	text-lg text-left mb-2">{sender.username}</div>
      ) : null}
      <div className={STYLES[direction].container}>
        {/* AVATAR MINUSCOLO {direction === MessageDirection.IN && (
        <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
          <img
            className="shadow-md rounded-full w-full h-full object-cover"
            src="https://randomuser.me/api/portraits/women/33.jpg"
            alt=""
          />
        </div>
      )} */}
        <div className={STYLES[direction].body}>
          {messages.map((message, i) => {
            const {
              id,
              content,
              photoAttachment,
              videoAttachment,
              audioAttachment,
              sendAt,
            } = message;

            const isFirstMessage = i === 0;
            const isLastMessage = i === messages.length - 1;

            const messageStyle = isFirstMessage
              ? STYLES[direction].first
              : isLastMessage
              ? STYLES[direction].last
              : STYLES[direction].center;

            const { distance, seconds } = formatDateDistance(sendAt);
            const formattedSendAt = seconds > 180 ? distance : 'Poco fa';

            return (
              <div key={id} className={STYLES[direction].group}>
                <p className="mr-1 text-xs text-gray-400">{formattedSendAt}</p>

                {photoAttachment && (
                  <div
                    className="flex w-64 h-64 mb-1 relative flex-shrink-0 max-w-xs lg:max-w-md"
                    onClick={() => onImageClick(photoAttachment)}
                  >
                    <img
                      className="absolute shadow-md w-full h-full rounded-l-lg object-cover"
                      src={photoAttachment.photoFile.url}
                      alt="Attachment"
                    />
                  </div>
                )}
                {videoAttachment && (
                  <div
                    className="flex bg-black w-64 h-40 relative flex-shrink-0 max-w-md z-10"
                    onClick={() =>
                      onVideoClick({
                        id: videoAttachment.videoFile.id,
                        videoFile: videoAttachment.videoFile,
                      })
                    }
                    onTouchStart={() =>
                      onVideoClick({
                        id: videoAttachment.videoFile.id,
                        videoFile: videoAttachment.videoFile,
                      })
                    }
                  >
                    <video
                      width="100%"
                      height="100%"
                      controls
                      controlsList="play nodownload nofullscreen"
                      muted
                    >
                      <source
                        src={`${videoAttachment.videoFile.url}#t=0.001`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
                {audioAttachment && (
                  <div className="flex w-64 relative flex-shrink-0 max-w-xs lg:max-w-md">
                    <audio controls controlsList="play nodownload nofullscreen">
                      <source
                        src={audioAttachment.audioFile.url}
                        type={audioAttachment.audioFile.type}
                      />
                    </audio>
                  </div>
                )}

                {content && <p className={messageStyle}>{content}</p>}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

interface IChatAreaContentProps {
  thread: IChatThread;
  messages: IChatMessage[];
  onScrollToTop: () => Promise<void>;
  showSenderUsername?: boolean;
}

export const ChatAreaContent = (props: IChatAreaContentProps) => {
  const { thread, messages, onScrollToTop, showSenderUsername = false } = props;

  const { id: threadId } = thread;

  const {
    saveReadReceiptMutation: { mutateAsync: saveReadReceipt },
  } = useSaveReadReceipt(threadId);

  useEffect(() => {
    if (!messages || !messages.length) {
      return;
    }

    // Update read checkmark
    const lastMessage = messages[messages.length - 1];
    const { id: lastMessageId, checkmark } = lastMessage;

    if (checkmark) {
      return;
    }

    // TODO: Controllare che il checkmark non sia già stato inserito
    // TODO: controllare che l'ultimo messaggio non sia il mio

    (async () => {
      try {
        await saveReadReceipt(lastMessageId);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [messages, saveReadReceipt]);

  const {
    openModal: viewMediaModalOpen,
    closeModal: viewMediaModalClose,
    modalData: viewMediaModal,
    setData: viewMediaModalSetData,
  } = useModal<IMedia2 | any>();

  const handleOnViewImage = (image: IMedia2) => {
    viewMediaModalSetData(image);
    viewMediaModalOpen();
  };

  const handleOnViewVideo = (video: any) => {
    viewMediaModalSetData(video);
    viewMediaModalOpen();
  };

  const { chatBoxRef, handleChatBoxScroll } = useAutoScroll({
    messages,
    onFetchNewMessages: onScrollToTop,
  });

  const messagesGroupByUser = useMemo(() => {
    return messageGroup(messages);
  }, [messages]);

  if (!messages) {
    return <>Non ci sono messaggi</>;
  }

  return (
    <>
      <MediaModal {...viewMediaModal} onClose={viewMediaModalClose} />
      <div
        className="chat-body p-4 flex-1 overflow-y-scroll"
        ref={chatBoxRef}
        onScroll={handleChatBoxScroll}
      >
        {messagesGroupByUser.map((group) => (
          <Fragment key={group.id}>
            <ChatBodyIncomingGroup
              showSenderUsername={showSenderUsername}
              onViewImage={handleOnViewImage}
              onViewVideo={handleOnViewVideo}
              {...group}
            />
          </Fragment>
        ))}
      </div>
    </>
    // <div className="chat-body p-4 flex-1 overflow-y-scroll" ref={chatBodyContainerRef}>
    //   {messagesGroupByUser.map(({ sender, messages }) => {
    //     if (user && user.communicationUserId === sender.communicationUserId) {
    //       return <ChatBodyOutcomingGroup sender={sender} messages={messages} />;
    //     } else {
    //       return <ChatBodyIncomingGroup sender={sender} messages={messages} />;
    //     }
    //   })}
    //   {/* <ChatBodyDaySeparator title="12:40 PM" />
    //   <ChatBodyOutcomingGroup /> */}
    // </div>
  );
};
