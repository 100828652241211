import React, { useState, useRef, useEffect, useCallback } from 'react';

// 3p
import { StopIcon, TrashIcon } from '@heroicons/react/outline';
import { Player } from '@lottiefiles/react-lottie-player';

// app
import { createUploadUrl, uploadFile } from 'api/chat';
// ts
// import { Mp3Encoder } from 'lamejs';
// import { WaveFile } from 'wavefile';

// const convertToMp3 = async (wavBlob: Blob, callback: (b: Blob) => void) => {
//   const wavArrayBuffer = await wavBlob.arrayBuffer();
//   const waveFile = new WaveFile();
//   waveFile.fromBuffer(wavArrayBuffer);

//   const sampleRate = (waveFile.fmt as any).sampleRate;
//   const samples = waveFile.getSamples(false, Int16Array);

//   const mp3encoder = new Mp3Encoder(1, sampleRate, 128);
//   const samplesUint8Array = new Uint8Array(samples.buffer);
//   const mp3Data = mp3encoder.encodeBuffer(samplesUint8Array);

//   mp3encoder.finish();

//   const audioBlob = new Blob([mp3Data], { type: 'audio/mp3' });

//   callback(audioBlob);
// };

// function convertToMp3(blob: Blob, callback: (blob: Blob) => void) {
//   const reader = new FileReader();

//   reader.onload = function () {
//     const wavData = reader.result as Uint8Array;
//     // const wav = lamejs.WavHeader.readHeader(new DataView(wavData));

//     const wav = new WaveFile();

//     try {
//       wav.fromBuffer(wavData);

//       // Configure encoder settings
//       // eslint-disable-next-line
//       const channels = (wav.fmt as any).numChannels;
//       const sampleRate = (wav.fmt as any).sampleRate;

//       const bitRate = 128;
//       const mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, bitRate);

//       //const samples = new Int16Array(wavData, wav.dataOffset);
//       const samples = wav.getSamples(false, Int16Array);
//       const length = samples.length;

//       // Encode audio samples to MP3
//       const mp3Data = [];
//       const samplesPerFrame = 1152;
//       for (let i = 0; i < length; i += samplesPerFrame) {
//         const sampleChunk = samples.subarray(i, i + samplesPerFrame);
//         const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
//         if (mp3buf.length > 0) {
//           mp3Data.push(mp3buf);
//         }
//       }

//       // Flush remaining data to the output buffer
//       const mp3buf = mp3encoder.flush();
//       if (mp3buf.length > 0) {
//         mp3Data.push(mp3buf);
//       }

//       // Combine all MP3 buffers into a single blob
//       const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });

//       // Call the callback function with the MP3 blob
//       callback(mp3Blob);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   reader.readAsArrayBuffer(blob);
// }

type Props = {
  showAttachmentAudio: boolean;
  onSuccess?: (file: File, fileName: string) => void;
  onCancel: () => void;
  onProgress: (value: number) => void;
};

export const ChatAttachmentAudio: React.FC<Props> = (props) => {
  const { showAttachmentAudio, onCancel, onSuccess, onProgress } = props;

  // const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  const [loadingSendAudio, setLoadingSendAudio] = useState<boolean>(false);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const handleStopRecording = () => {
    mediaRecorderRef.current!.stop();
  };

  const handleDeleteRecording = () => {
    setAudioBlob(null);
    onCancel();
  };

  const streamRef = useRef<MediaStream>();

  const handleStartRecording = useCallback(async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });

      mediaRecorderRef.current = new MediaRecorder(streamRef.current);

      const audioChunks: Blob[] = [];
      mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorderRef.current.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });

        setAudioBlob(audioBlob);

        streamRef.current!.getTracks().forEach((track) => {
          track.stop();
        });

        mediaRecorderRef.current = null;
        streamRef.current = undefined;
      });

      mediaRecorderRef.current.start();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (showAttachmentAudio) {
      handleStartRecording();
    }
  }, [showAttachmentAudio, handleStartRecording]);

  function updateProgress(value: number) {
    if (onProgress) {
      onProgress(value);
    }
  }

  // Carico il file su s3 e chiamo il callback
  const handleSendAudio = async () => {
    if (loadingSendAudio) return;

    setLoadingSendAudio(true);

    if (!audioBlob) {
      throw new Error('No audio');
    }

    const audioFile = new File([audioBlob], 'recording.mp3', { type: 'audio/mp3' });

    try {
      const { presignedUrl, fileName } = await createUploadUrl(audioFile);

      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentComplete = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total!
          );

          updateProgress(percentComplete);
        },
      };

      await uploadFile(presignedUrl, audioFile, config);

      if (onSuccess) {
        onSuccess(audioFile, fileName);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSendAudio(false);
    }
  };

  return audioBlob ? (
    <>
      <button type="button" onClick={handleDeleteRecording}>
        <TrashIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
      </button>
      <audio src={URL.createObjectURL(audioBlob)} controls />
      <button
        type="button"
        className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700"
        onClick={handleSendAudio}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
          />
        </svg>
      </button>
    </>
  ) : (
    <>
      <div>
        <Player
          src="https://assets1.lottiefiles.com/private_files/lf30_lv4zofni.json"
          background="transparent"
          speed={1}
          style={{ height: 50 }}
          loop
          autoplay
        />
      </div>
      <button type="button" onClick={handleStopRecording}>
        <StopIcon className="h-8 w-8 text-gray-500" aria-hidden="true" />
      </button>
    </>
  );
};
