import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

// 3p
import { IChatMessage } from 'interfaces';
import { useAdminGetThread, useAdminGetAllMessage } from 'api/admin/chat';

// app
import { ChatAreaContent, ChatAreaHeader } from 'components/common/chat';
import { Loader } from 'components/common';

export const Conversation = () => {
  const { chatId } = useParams();

  // Load thread
  const { adminGetThreadQuery } = useAdminGetThread(chatId);

  const {
    data: threadQueryResult,
    isLoading: threadQueryIsLoading,
    // isError: threadQueryIsError,
    // error: threadQueryError,
  } = adminGetThreadQuery;

  // Load messages
  const { adminGetAllThreadMessageQuery } = useAdminGetAllMessage(chatId);

  const {
    data: messageQueryResult,
    // isError: messageQueryIsError,
    // error: messageQueryError,
    // isLoading: messageQueryIsLoading,
    // isFetching,
    fetchPreviousPage,
    hasPreviousPage,
  } = adminGetAllThreadMessageQuery;

  const [messages, setMessages] = useState<IChatMessage[]>([]);

  useEffect(() => {
    if (!messageQueryResult) {
      return;
    }

    setMessages(messageQueryResult.pages.flatMap((page) => page.data));
  }, [messageQueryResult]);

  const onScrollToTop = useCallback(async () => {
    if (hasPreviousPage) {
      await fetchPreviousPage();
    }
  }, [hasPreviousPage, fetchPreviousPage]);

  if (threadQueryIsLoading || !threadQueryResult) {
    return <Loader />;
  }

  return (
    <>
      <ChatAreaHeader thread={threadQueryResult} />
      <ChatAreaContent
        thread={threadQueryResult}
        showSenderUsername
        messages={messages}
        onScrollToTop={onScrollToTop}
      />
    </>
  );
};
