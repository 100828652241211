import styles from './VideoCall.module.css';

interface IMicrophoneButtonProps {
  isMicrophoneEnable: boolean;
  startMicrophoneButton: () => void;
  stopMicrophoneButton: () => void;
}

export function MicrophoneButton(props: IMicrophoneButtonProps) {
  const { isMicrophoneEnable, startMicrophoneButton, stopMicrophoneButton } = props;

  const btnAction = isMicrophoneEnable ? stopMicrophoneButton : startMicrophoneButton;
  const btnStyle = isMicrophoneEnable ? styles.btnCamera : styles.btnDisabled;

  return (
    <button
      onClick={btnAction}
      className={`${btnStyle} transform active:scale-75 transition-transform`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
        />
      </svg>
    </button>
  );
}
