// 3p
import { Dialog } from '@headlessui/react';

// app

import { ModalLayout } from 'components/common';
import { createContext, useCallback, useContext, useRef, useState } from 'react';

interface IIncomingCallModalProps {
  displayName?: string;
  open: boolean;
  onAccept: () => void;
  onReject: () => void;
  onClose: () => void;
}

export const IncomingCallModal = (props: IIncomingCallModalProps) => {
  const { displayName, open, onAccept, onReject, onClose } = props;
  return (
    <ModalLayout open={open} onClose={onClose} isLoading={false}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Richiesta chiamata
          </Dialog.Title>
          <h2>
            Nuova chiamata in arrivo da <b>{displayName}</b>
          </h2>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onAccept}
        >
          Accetta
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onReject}
        >
          Rifiuta
        </button>
      </div>
    </ModalLayout>
  );
};

interface IOutgoingCallModalProps {
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
}

export const OutgoindCallModal = (props: IOutgoingCallModalProps) => {
  const { open, onCancel, onClose } = props;
  return (
    <ModalLayout open={open} onClose={onClose} isLoading={false}>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Chiamata in corso
          </Dialog.Title>

          <h2>Connessione in corso</h2>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onCancel}
        >
          Annulla
        </button>
      </div>
    </ModalLayout>
  );
};

/** CONTEXT */

interface IIncomingCallContext {
  showIncomingCall: (
    acceptCb: Function,
    rejectCb: Function,
    displayName?: string
  ) => void;
  closeIncomingCall: () => void;
  showOutcomingCall: (cancelCb: Function) => void;
  closeOutcomingCall: () => void;
}

interface IIncomingCallContextProvider {
  children: JSX.Element | JSX.Element[];
}

const IncomingCallContext = createContext<IIncomingCallContext>(
  {} as IIncomingCallContext
);

export function IncomingCallContextProvider({
  children,
}: IIncomingCallContextProvider): JSX.Element {
  // INCOMING MODAL
  const [displayName, setDisplayName] = useState<string>();
  const [showIncomingModal, setShowIncomingModal] = useState<boolean>(false);

  const acceptCbRef = useRef<Function>();
  const rejectCbRef = useRef<Function>();

  const showIncomingCall = useCallback(
    (acceptCb: Function, rejectCb: Function, displayName?: string) => {
      acceptCbRef.current = acceptCb;
      rejectCbRef.current = rejectCb;

      setDisplayName(displayName);

      setShowIncomingModal(true);
    },
    []
  );

  const closeIncomingCall = useCallback(() => {
    acceptCbRef.current = undefined;
    rejectCbRef.current = undefined;

    setShowIncomingModal(false);
    setDisplayName(undefined);
  }, []);

  const incomingCallAccept = useCallback(() => {
    if (acceptCbRef.current) {
      acceptCbRef.current();
    }

    closeIncomingCall();
  }, [closeIncomingCall]);

  const incomingCallReject = useCallback(() => {
    if (rejectCbRef.current) {
      rejectCbRef.current();
    }

    closeIncomingCall();
  }, [closeIncomingCall]);

  // OUTCOMING MODAL
  const [showOutcomingModal, setShowOutcomingModal] = useState<boolean>(false);

  const cancelCbRef = useRef<Function>();

  const showOutcomingCall = useCallback((cancelCb: Function) => {
    cancelCbRef.current = cancelCb;

    setShowOutcomingModal(true);
  }, []);

  const closeOutcomingCall = useCallback(() => {
    cancelCbRef.current = undefined;

    setShowOutcomingModal(false);
  }, []);

  const outcomingCallCancel = useCallback(() => {
    if (cancelCbRef.current) {
      cancelCbRef.current();
    }

    closeOutcomingCall();
  }, [closeOutcomingCall]);

  return (
    <IncomingCallContext.Provider
      value={{
        showIncomingCall,
        closeIncomingCall,
        showOutcomingCall,
        closeOutcomingCall,
      }}
    >
      {children}
      <IncomingCallModal
        displayName={displayName}
        open={showIncomingModal}
        onAccept={incomingCallAccept}
        onReject={incomingCallReject}
        onClose={closeIncomingCall}
      />
      <OutgoindCallModal
        open={showOutcomingModal}
        onCancel={outcomingCallCancel}
        onClose={closeOutcomingCall}
      />
    </IncomingCallContext.Provider>
  );
}

export function useIncomingCall() {
  return useContext(IncomingCallContext);
}
