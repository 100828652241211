// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { IChatThread } from 'interfaces';
import { ENV_COMMUNICATION } from 'config';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks';

const getAllThread = async ({ pageParam, queryKey }: any) => {
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IChatThread[]>(key, {
    params: {
      ...searchQuery,
      ...pagingQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return data;
};

export function useGetAllThread(searchQuery?: any, pagingQuery?: any) {
  const queryKey = [ENV_COMMUNICATION.GET_ALL_THREAD, searchQuery, pagingQuery];

  const { user } = useAuth();

  const getAllThreadQuery = useQuery<IChatThread[], AxiosError<string, any>>({
    queryKey: queryKey,
    queryFn: getAllThread,
    enabled: !!user,
  });

  return {
    getAllThreadQuery,
    queryKey,
  };
}
