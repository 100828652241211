import { Fragment, useEffect, useMemo } from 'react';

// 3p
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Switch, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { IMediaApproveModalForm } from 'interfaces';

import Loader from 'components/common/Loader';

import { classNames } from 'utils';
import { useApproveMedia } from 'api/admin/media/useApproveMedia';

interface IMediaApproveModalProps {
  data?: IMediaApproveModalForm;
  open: boolean;
  onSuccess?: (data?: any) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const MediaApproveModal = (props: IMediaApproveModalProps) => {
  const { data, open, onSuccess, onError, onClose } = props;

  const { useApproveMediaMutation } = useApproveMedia();
  const { mutateAsync, isLoading } = useApproveMediaMutation;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IMediaApproveModalForm>({
    defaultValues: useMemo(
      () => ({
        mediaId: data?.mediaId,
        mediaType: data?.mediaType,
        hasApprove: data?.hasApprove ? data?.hasApprove : false,
      }),
      [data]
    ),
  });

  useEffect(() => {
    reset({
      mediaId: data?.mediaId,
      mediaType: data?.mediaType,
      hasApprove: data?.hasApprove ? data?.hasApprove : false,
    });
  }, [data, reset]);

  const onSubmit: SubmitHandler<IMediaApproveModalForm> = async (data) => {
    const { hasApprove, mediaId, mediaType } = data;

    try {
      await mutateAsync({ hasApprove, mediaId, mediaType });
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };

  const renderLoader = () => (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div className="m-3 sm:m-5 text-center">
        <Loader />
      </div>
    </div>
  );

  const renderData = () => (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Revisione media
          </Dialog.Title>

          {/* {data?.status.code === TrasnferStatusCode.PENDING && ( */}
          <div className="mt-2 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <Switch.Group as="div" className="flex items-start justify-between">
                <span className="flex-grow flex flex-col">
                  <Switch.Label
                    as="span"
                    className="block text-md font-medium text-gray-700"
                    passive
                  >
                    <span className="block text-sm font-medium text-gray-700">
                      Approva media
                    </span>
                  </Switch.Label>
                </span>

                <Controller
                  name="hasApprove"
                  control={control}
                  defaultValue={false}
                  //rules={{ required: "Approva media" }}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      onChange={onChange}
                      className={classNames(
                        value ? 'bg-pink-600' : 'bg-gray-200',
                        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          value ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                  )}
                />
              </Switch.Group>
              {errors.hasApprove?.type === 'required' ? (
                <p className="mt-2 text-red-500 text-sm">{errors.hasApprove.message}</p>
              ) : null}
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        {/* {data?.status.code === TrasnferStatusCode.PENDING && ( */}
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Salva
        </button>
        {/* )} */}
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Chiudi
        </button>
      </div>
    </form>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {isLoading || !data ? renderLoader() : renderData()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
