import { FormEvent, useCallback, useRef, useState } from 'react';

// 3p
import { MicrophoneIcon, XIcon } from '@heroicons/react/outline';

// App
import { IChatConverAudio, IChatThread } from 'interfaces';
import { IMAGE_EXT, VIDEO_EXT } from 'config';

import { useChat } from 'hooks';
import { generateVideoThumbnail } from 'utils';

import { ChatAttachmentMedia } from './ChatAttachmentMedia';
import { ChatAttachmentAudio } from './ChatAttachmentAudio';

import APIClient from 'api/ApiClient';

interface IChatAreaFooterProps {
  thread: IChatThread;
}

export const ChatAreaFooter = (props: IChatAreaFooterProps) => {
  const { thread } = props;

  const [attachment, setAttachment] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [progress, setProgress] = useState<number>();

  const [showAttachmentAudio, setShowAttachmentAudio] = useState(false);

  const { onSendMessage, onSendAttachment } = useChat();

  const [loadingSendMessage, setLoadingSendMessage] = useState<boolean>(false);

  const textareaRef = useRef(null);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (loadingSendMessage) return;

    setLoadingSendMessage(true);

    // Read the form data
    const form = e.currentTarget;
    const formData = new FormData(form);

    const message = formData.get('message');

    // You can pass formData as a fetch body directly:
    // fetch('/some-api', { method: form.method, body: formData });

    // Or you can work with it as a plain object:
    // const formJson = Object.fromEntries(formData.entries());

    form.reset();
    adjustTextareaHeight();

    try {
      if (message) {
        const messageSafe = message.toString().trim();

        if (messageSafe.length < 1 || messageSafe.length > 1000) {
          alert('Il numero massimo di caratteri per i messaggi è 1000');
          return false;
        }

        await onSendMessage(thread.id, messageSafe, attachment);
      } else if (attachment) {
        await onSendAttachment(thread.id, attachment);
      }

      handleRemoveAttachment();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSendMessage(false);
    }
    return false;
  }

  const handleOnSuccessAttachmentMedia = useCallback(
    async (file: File, fileName: string) => {
      // Controllo se è un video o una foto
      const isFileVideo = VIDEO_EXT.includes(file.type);
      const isFileImage = IMAGE_EXT.includes(file.type);

      let previewUrl;
      let fileCategory;

      // se è un video creo la preview
      if (isFileVideo) {
        // Video preview (è stato rimossa perchè nel body era troppo grande)
        previewUrl = await generateVideoThumbnail(file);
      } else if (isFileImage) {
        // Image preview
        previewUrl = URL.createObjectURL(file);
      } else {
      }

      setPreviewUrl(previewUrl);
      setProgress(undefined);

      try {
        // NOT WORKING
        // const fileHash = await calculateMD5(file);

        const attachment = {
          fileName,
          fileHash: null,
          fileCategory,
          mimeType: file.type,
          fileSize: file.size,
        };

        setAttachment(attachment);
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const handleProgressAttachment = useCallback((value: number) => {
    setProgress(value);
  }, []);

  const handleRemoveAttachment = useCallback(() => {
    setPreviewUrl(undefined);
    setAttachment(undefined);
  }, []);

  const handleOnSuccessAttachmentAudio = useCallback(
    async (file: File, fileName: string) => {
      setShowAttachmentAudio(false);

      try {
        const result = await APIClient.post<IChatConverAudio>(
          `/communications/convertAudio`,
          { fileName }
        );

        const { newFileName, newFileHash } = result.data;

        const attachment = {
          fileName: newFileName,
          fileHash: newFileHash,
          mimeType: file.type,
          fileSize: file.size,
        };

        setAttachment(attachment);

        await onSendAttachment(thread.id, attachment);
      } catch (error) {
        console.error(error);
      } finally {
        setProgress(undefined);
      }
    },
    [onSendAttachment, thread]
  );

  const adjustTextareaHeight = () => {
    const textarea: any = textareaRef.current;
    if (textarea) {
      // Resetto l'altezza
      textarea.style.height = 'auto';
      const newHeight = textarea.scrollHeight + 'px';
      textarea.style.height =
        Math.min(
          parseFloat(newHeight),
          3 * parseFloat(getComputedStyle(textarea).lineHeight)
        ) + 'px';

      // const currentRows = Math.floor(
      //   textarea.scrollHeight / parseInt(getComputedStyle(textarea).lineHeight)
      // );
      // setRows(currentRows);

      // Verifica se la scrollbar è necessaria e imposta l'overflowY di conseguenza
      textarea.style.overflowY =
        textarea.scrollHeight - 3 > textarea.clientHeight ? 'auto' : 'hidden';
    }
  };

  const handleChange = (event: any) => {
    adjustTextareaHeight();
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div className="chat-footer flex-none">
        {progress && (
          <div
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '5px 20px',
            }}
            className="bg-gray-200 flex"
          >
            <div className="h-5 w-full bg-pink-200">
              <div
                className="h-5 bg-pink-600 transition-all"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}

        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 20px',
            display: !progress && previewUrl ? 'flex' : 'none',
            maxHeight: 100,
          }}
          className="bg-gray-200 flex"
        >
          <div>
            <img
              src={previewUrl}
              alt="Attachment Preview"
              style={{ height: 100, width: 100, objectFit: 'cover' }}
            />
          </div>

          <div>
            <button onClick={handleRemoveAttachment}>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        {!progress && showAttachmentAudio && (
          <div
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '25px 20px',
            }}
            className="bg-gray-200 flex"
          >
            <ChatAttachmentAudio
              showAttachmentAudio={showAttachmentAudio}
              onCancel={() => setShowAttachmentAudio(false)}
              onSuccess={handleOnSuccessAttachmentAudio}
              onProgress={handleProgressAttachment}
            />
          </div>
        )}

        {!progress && !showAttachmentAudio && (
          <div className="flex flex-row items-center p-4">
            <ChatAttachmentMedia
              onSuccess={handleOnSuccessAttachmentMedia}
              onProgress={handleProgressAttachment}
            />
            <button
              type="button"
              className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700"
              onClick={() => setShowAttachmentAudio(true)}
            >
              <MicrophoneIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="relative flex-grow">
              <label>
                <textarea
                  ref={textareaRef}
                  autoComplete="none"
                  className="rounded-md py-2 pl-3 pr-10 w-full border border-gray-200 bg-gray-200 focus:bg-white focus:outline-none text-gray-600 focus:shadow-md transition duration-300 ease-in"
                  rows={1}
                  style={{
                    minHeight: 'fit-content',
                    resize: 'none',
                    overflowY: 'hidden',
                  }}
                  name="message"
                  placeholder="Scrivi un messaggio"
                  maxLength={1000}
                  onChange={handleChange}
                />
              </label>
            </div>
            <button
              type="submit"
              className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </form>
  );
};
