import { Navigate, Route, Routes } from 'react-router-dom';

import CreatorDashboardView from './Dashboard';
import CreatorProductView from './Products';
import CreatorSettingsView from './Settings';
import CreatorPayoutView from './Payout';
import PurchaseView from './Purchase';

import { Chat, VideoCall, Conversation } from './chat';
import { AlbumRouter } from './album';

import { useAuth } from 'hooks';
import { ROLES } from 'config';

import PageNotFound from 'views/web/PageNotFound';
import { ChatLayout, MainLayout } from 'layouts/web';

export const AppRouter = () => {
  const { user } = useAuth();

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={`dashboard`} element={<CreatorDashboardView />} />

        <Route path={`albums/*`} element={<AlbumRouter />} />

        <Route path={`products`} element={<CreatorProductView />} />

        <Route path={`settings/*`} element={<CreatorSettingsView />} />

        <Route path={`payouts`} element={<CreatorPayoutView />} />

        <Route path={`purchases`} element={<PurchaseView />} />

        {user?.role.code === ROLES.CUSTOMER ? (
          <Route index element={<Navigate to={`dashboard`} />} />
        ) : (
          <Route index element={<Navigate to={`payouts`} />} />
        )}

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path={`chat`} element={<ChatLayout />}>
        <Route path="" element={<Chat />}>
          <Route path=":chatId" element={<Conversation />} />
        </Route>
      </Route>

      <Route path="call">
        <Route path={`:callId`} element={<VideoCall />} />
      </Route>
    </Routes>
  );
};
