import { useEffect, useState } from 'react';

// 3p
import { useParams } from 'react-router-dom';
import { ChatMessageReceivedEvent } from '@azure/communication-chat';
import { UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';

// app
import { IChatThread } from 'interfaces';
import { useChat } from 'hooks';

import { ChatThreadItem } from './ChatThreadItem';

// {
//   id: 8,
//   img: [
//     'https://randomuser.me/api/portraits/men/22.jpg',
//     'https://randomuser.me/api/portraits/men/55.jpg',
//   ],
//   name: 'TailwindCSS Group',
//   message: 'Adam: Hurray, Version 2 is out now!!.',
//   time: '23 Jan',
//   unread: false, // bollino rosa che segna messaggi non letti
//   lastseen: false, // avatar piccolo segna ultima lettura
//   isonline: true, // bollino verde che segna se è visualizzato sull'avatar
// },

interface IChatThreadListProps {
  query: UseQueryResult<IChatThread[], AxiosError<string, any>>;
  onItemClick: (id: string) => string;
}

export const ChatThreadList = (props: IChatThreadListProps) => {
  const { query, onItemClick } = props;
  const { data } = query;

  const [threads, setThreads] = useState<IChatThread[]>([]);

  useEffect(() => {
    if (data) {
      setThreads(data);
    }
  }, [data]);

  const { chatId } = useParams();
  const { client, isConnected } = useChat();

  useEffect(() => {
    if (!isConnected || !client) {
      return;
    }

    const onChatMessageReceived = (e: ChatMessageReceivedEvent) => {
      const eventThreadId = e.threadId;
      const senderCommunicationUserId =
        e.sender.kind === 'communicationUser' ? e.sender.communicationUserId : '';

      setThreads((threads) => {
        const thread = threads.find((t) => t.azId === eventThreadId);

        if (!thread) {
          return threads;
        }

        const threadWithMessage = {
          ...thread,
          message: {
            content: e.message,
            sendAt: e.metadata['sendAt'],
            createAt: e.createdOn.toLocaleString(),
            checkmark: null,
            senderCommunicationUserId,
          },
        };

        const threadsWithOutCurr = threads.filter((t) => t.azId !== eventThreadId);
        const threadsWithNewThread = [threadWithMessage, ...threadsWithOutCurr];

        return threadsWithNewThread.sort((a, b) => {
          // ATTENZIONE: le coppie vengono confrontate in questo modo: a = secondo elemento dell'array, b = primo elemento dell'array
          // esempio primo giro: array = [3,2,5,1] -> a=2,b=3; secondo giro: a=5,b=2 e così via
          if (!a.message.checkmark && b.message.checkmark) {
            // a non è letto e b è stato letto
            return -1; // con valore negativo metto a prima di b
          } else if (a.message.checkmark && !b.message.checkmark) {
            // a è letto e b non è stato letto
            return 1; // con valore positivo metto b prima di a
          } else {
            //a letto e b letto
            return (
              new Date(b.message.sendAt!).getTime() -
              new Date(a.message.sendAt!).getTime()
            );
          }
        });
      });
    };

    client.on('chatMessageReceived', onChatMessageReceived);

    return () => {
      client.off('chatMessageReceived', onChatMessageReceived);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, isConnected]);

  return (
    <>
      {threads.map((thread) => (
        <ChatThreadItem key={thread.id} onClick={onItemClick} {...thread} />
      ))}
    </>
  );
};
