import { useMemo } from 'react';

//3p
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { LogoutIcon, MenuIcon, XIcon } from '@heroicons/react/outline';

// app
import { ROLES } from 'config/Roles';
import { MENU_ITEMS, MENU_QUICK } from 'config/Menu';
import {
  CURRENT_LOGO_URI,
  CURRENT_SITE_NAME,
  IS_ENABLE_SIGNUP_CREATOR,
} from 'config/Config';

import { UserImage } from 'components/common/image';
import { Tooltip } from 'components/common/tooltip';
import { WalletTopUpModal } from 'components/customer/payment';

import { HeaderDropdown } from 'layouts/HeaderDropdown';

import { useAuth, useChat, useModal } from 'hooks';

import { filterMenuItemByRole } from 'utils';

import { HeaderWalletSummary } from './HeaderWalletSummary';

export default function Header() {
  const { user, signout } = useAuth();

  const {
    openModal: walletTopUpModalOpen,
    closeModal: walletTopUpModalClose,
    modalData: topupModalData,
  } = useModal();

  const { hasNewMessage } = useChat();

  const renderMenuItem = useMemo(() => {
    const filterByRole = filterMenuItemByRole(MENU_ITEMS, user?.role.code);

    return filterByRole.map(({ path, title, icon: Icon, attr, badge }) => (
      <Menu.Item
        as={Link}
        key={path}
        to={path}
        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
        {...attr}
      >
        <div className="shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-pink-500 text-white">
          <Icon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-4 text-base font-medium text-gray-900">{title}</div>
        {badge === 'new-message' && hasNewMessage && (
          <div className="ml-auto">
            <span className="block h-3.5 w-3.5 rounded-full bg-pink-500 ring-2 ring-white" />
          </div>
        )}
      </Menu.Item>
    ));
  }, [hasNewMessage, user]);

  const renderMenuQuick = useMemo(() => {
    const filterByRole = filterMenuItemByRole(MENU_QUICK, user?.role.code);

    return filterByRole.map(({ path, title, icon: Icon, attr, badge }) => {
      return (
        <Menu.Item
          as={Link}
          key={path}
          to={path}
          className="relative inline-block text-sm text-gray-500 p-2 rounded-md hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          {...attr}
        >
          <Tooltip text={title}>
            <Icon className="h-7 w-7" aria-hidden="true" />
          </Tooltip>
          {badge === 'new-message' && hasNewMessage && (
            <span className="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-pink-500 ring-2 ring-white" />
          )}
        </Menu.Item>
      );
    });
  }, [hasNewMessage, user]);

  return (
    <>
      <WalletTopUpModal
        {...topupModalData}
        onClose={walletTopUpModalClose}
        onSuccess={() => {}}
      />
      <Menu as="div" className="relative bg-white shadow">
        <div className="flex justify-between items-center py-3 px-4 sm:px-6 lg:px-8 md:justify-start md:space-x-10 max-w-7xl mx-auto">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">{CURRENT_SITE_NAME}</span>
              <img
                className="block h-8 w-auto"
                src={CURRENT_LOGO_URI}
                alt={CURRENT_SITE_NAME}
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden relative inline-block">
            <Menu.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
            {hasNewMessage && (
              <span className="absolute right-0 top-0 block h-3.5 w-3.5 rounded-full bg-pink-500 ring-2 ring-white" />
            )}
          </div>
          {/* <nav className="hidden md:flex space-x-10"></nav> */}
          <nav className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {user ? (
              <>
                {renderMenuQuick}

                {user.role.code !== ROLES.ADMIN && (
                  <HeaderWalletSummary
                    containerClassName="cursor-pointer inline-flex items-center text-sm text-gray-500 p-2 rounded-md hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    iconClassName="h-7 w-7"
                    textClassName="ml-2 text-lg font-medium"
                    onClick={() => {
                      if (user.role.code === ROLES.CUSTOMER) {
                        walletTopUpModalOpen();
                      }
                    }}
                  />
                )}

                <HeaderDropdown />
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="mr-6 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Accedi
                </Link>
                <Link
                  to="/signup/customer"
                  className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                >
                  Registrati
                </Link>
              </>
            )}
          </nav>
        </div>

        <Menu.Items className="z-40 absolute top-0 inset-x-0 p-2 transition origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <Menu.Item as={Link} to="/">
                  <img
                    className="h-8 w-auto"
                    src={CURRENT_LOGO_URI}
                    alt={CURRENT_SITE_NAME}
                  />
                </Menu.Item>
                <div className="-mr-2">
                  <Menu.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Menu.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid grid-cols-1 gap-7">
                  {user && user.role.code === ROLES.CREATOR && (
                    <>
                      <Menu.Item
                        as={Link}
                        to={user.role.code === ROLES.CREATOR ? `/${user.username}` : '#'}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white">
                          <UserImage
                            image={user.image}
                            alt={user.username}
                            className="h-10 w-10 rounded-md"
                          />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {user.username}
                        </div>
                      </Menu.Item>
                      <div className="border-t border-gray-300 divide-y divide-gray-700" />
                    </>
                  )}
                  {user && user.role.code === ROLES.CUSTOMER && (
                    <>
                      <Menu.Item
                        as="div"
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="items-center justify-center h-10 w-10 rounded-md text-white">
                          <UserImage
                            image={user.image}
                            alt={user.username}
                            className="h-10 w-10 rounded-md"
                          />
                        </div>

                        <div className="flex-1 ml-4">
                          <div className="text-base font-medium text-gray-700">
                            {user.username}
                          </div>
                          <HeaderWalletSummary
                            containerClassName="cursor-pointer inline-flex items-center text-base font-medium text-gray-700 mt-1"
                            iconClassName="h-5 w-5"
                            textClassName="font-medium ml-1"
                          />
                        </div>

                        <div
                          onClick={walletTopUpModalOpen}
                          className="ml-3 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        >
                          Ricarica
                        </div>
                      </Menu.Item>
                      <div className="border-t border-gray-300 divide-y divide-gray-700" />
                    </>
                  )}
                  {renderMenuItem}
                  {user && (
                    <>
                      <div className="border-t border-gray-300 divide-y divide-gray-700" />
                      <Menu.Item
                        as="div"
                        onClick={signout}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                      >
                        <div className="shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-pink-500 text-white">
                          <LogoutIcon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          Logout
                        </div>
                      </Menu.Item>
                    </>
                  )}
                </nav>
              </div>
            </div>
            {!user && (
              <div className="py-6 px-5">
                <Menu.Item
                  as={Link}
                  to="/signup/customer"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                >
                  Registrati come cliente
                </Menu.Item>
                {/* <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Sei un creator?{' '} */}
                <Menu.Item
                  as={Link}
                  to={IS_ENABLE_SIGNUP_CREATOR === true ? '/signup/creator' : '/support'}
                  //className="text-pink-600 hover:text-pink-500"
                  className="w-full mt-4 flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                >
                  Registrati come creator
                </Menu.Item>
                {/* </p> */}
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Hai un account?{' '}
                  <Menu.Item
                    as={Link}
                    to="/login"
                    className="text-pink-600 hover:text-pink-500"
                  >
                    Accedi
                  </Menu.Item>
                </p>
              </div>
            )}
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
}
