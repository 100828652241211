import { useEffect, useState } from 'react';

import { isAfter } from 'date-fns';

// app
import { ENV_COMMUNICATION, LOCAL_STORAGE_KEY } from 'config';
import { ICommunicationAccessToken } from 'interfaces';

import APIClient from 'api/ApiClient';
import { useAuth } from 'hooks/useAuth';

export function useCommunicationToken() {
  const { user } = useAuth();

  const [communicationToken, setCommunicationToken] =
    useState<ICommunicationAccessToken>();

  useEffect(() => {
    if (!user) {
      return;
    }

    (async function () {
      console.log('setCommunicationToken check');

      const token = localStorage.getItem(LOCAL_STORAGE_KEY.COMMUNICATION_TOKEN);

      if (token) {
        const jsonToken = JSON.parse(token) as ICommunicationAccessToken;

        if (isAfter(new Date(jsonToken.expiresOn), new Date())) {
          setCommunicationToken(jsonToken);
          return;
        }
      }

      const { data } = await APIClient.get<ICommunicationAccessToken>(
        ENV_COMMUNICATION.REQUEST_TOKEN
      );

      localStorage.setItem(LOCAL_STORAGE_KEY.COMMUNICATION_TOKEN, JSON.stringify(data));

      setCommunicationToken(data);
    })();

    return () => {
      console.log('setCommunicationToken undefined');
      setCommunicationToken(undefined);
    };
  }, [user]);

  return Object.freeze({ communicationToken });
}
