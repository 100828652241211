import { Fragment, useEffect } from 'react';

// 3p
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import styles from 'styles';

import Loader from 'components/common/Loader';

import { useSetUserApproveAt } from 'api/admin/user/useSetUserApproveAt';

interface IApproveFormModal {
  isApprove: string;
  rejectionMotivation: string;
}

interface IApproveFormModalProps {
  data?: any;
  open: boolean;
  onSuccess?: () => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const ApproveFormModal = (props: IApproveFormModalProps) => {
  const { data, open, onSuccess, onError, onClose } = props;

  const { setUserApproveAtMutation } = useSetUserApproveAt(data?.id);
  const { mutateAsync, isLoading } = setUserApproveAtMutation;

  const { handleSubmit, reset, register, watch } = useForm<IApproveFormModal>();

  // Set Default data or clean
  useEffect(() => {
    if (data) {
    } else {
      reset({});
    }
  }, [open, data, reset]);

  const onSubmit: SubmitHandler<IApproveFormModal> = async (formData) => {
    try {
      await mutateAsync(formData);

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  const renderLoader = () => (
    <div className="m-3 sm:m-5 text-center">
      <Loader />
    </div>
  );

  const isApprove = watch('isApprove', 'false');

  const renderData = () => (
    <>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
            <Dialog.Title as="h3" className={styles.modal.title}>
              Verifica profilo
            </Dialog.Title>
            <div className="mt-5 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              {/* {errors.code && (
                <div className="sm:col-span-3">
                  <AlertError message={errors.code.message} />
                </div>
              )} */}
              <div className="sm:col-span-3">
                {/* <label className="text-base font-semibold text-gray-900">
                  Verificare il profilo ?
                </label> */}
                <p className="text-sm text-gray-500">
                  L'utente riceverà un email con la scelta selezionata.
                </p>
                <fieldset className="mt-4">
                  <legend className="sr-only">Verificare il profilo </legend>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <input
                        id="approve-confirm"
                        type="radio"
                        value="true"
                        className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-pink-600"
                        {...register('isApprove')}
                      />
                      <label
                        htmlFor="approve-confirm"
                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                        Conferma
                      </label>
                    </div>

                    <div className="flex items-center">
                      <input
                        id="approve-reject"
                        type="radio"
                        value="false"
                        className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-pink-600"
                        {...register('isApprove')}
                      />
                      <label
                        htmlFor="approve-reject"
                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                        Rifiuta
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>

              {isApprove === 'false' && (
                <div className="sm:col-span-3">
                  <label htmlFor="rejectionMotivation" className={styles.input.label}>
                    Motivazione rifiuto
                  </label>
                  <div className={styles.input.containerRelative}>
                    <textarea
                      className="h-48 w-full"
                      id="rejectionMotivation"
                      autoComplete="off"
                      {...register('rejectionMotivation')}
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:flex sm:flex-row-reverse">
          <button type="submit" className={styles.modal.button1}>
            Invia
          </button>
          <button type="button" className={styles.modal.button2} onClick={onClose}>
            Chiudi
          </button>
        </div>
      </form>
    </>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {isLoading ? renderLoader() : renderData()}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
