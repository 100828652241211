import { Dialog } from '@headlessui/react';

import Loader from '../Loader';

export const ContentLoading = () => (
  <>
    <div className="mt-3 text-center sm:mt-5">
      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
        Caricamento
      </Dialog.Title>
      <div className="mt-2">
        <Loader />
      </div>
    </div>
  </>
);
