import { Outlet, useParams } from 'react-router-dom';

import { ChatThreadList } from 'components/common/chat';
import { useAdminGetAllThread } from 'api/admin/chat';
import { useCallback } from 'react';

export const Chat = () => {
  const { chatId } = useParams();

  const sidebarStyle = chatId ? 'hidden sm:block' : '';

  const { adminGetAllThreadQuery } = useAdminGetAllThread();

  const onItemClick = useCallback((id: string) => {
    return `/admin/chat/${id}`;
  }, []);

  return (
    <main className="flex-grow flex flex-row min-h-0 bg-white">
      <section
        className={`flex flex-col overflow-auto w-full sm:w-24 lg:max-w-sm md:w-2/5 ${sidebarStyle}`}
      >
        {/* <ChatThreadSearch /> */}
        <ChatThreadList query={adminGetAllThreadQuery} onItemClick={onItemClick} />
      </section>

      <section className="flex flex-col flex-auto">
        <Outlet />
      </section>
    </main>
  );
};
