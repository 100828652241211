// std
import { useCallback, useEffect, useRef } from 'react';

// 3p
import { useVideoCall } from 'hooks';
import { useNavigate } from 'react-router-dom';

import {
  HangUpButton,
  MicrophoneButton,
  SwitchCameraButton,
  VideoCameraButton,
} from 'components/common/chat/video';

import styles from './VideoCall.module.css';

function removeAllChildNodes(parent: any) {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

export function VideoCall() {
  // const { callId } = useParams();

  const navigate = useNavigate();

  const {
    callStatus,
    startVideoButton,
    stopVideoButton,
    endCall,
    localVideoStream,
    remoteVideoStream,
    isMuted,
    startMicrophoneButton,
    stopMicrophoneButton,
    switchCamera,
  } = useVideoCall();

  /**
   * Display your local video stream preview in your UI
   */
  const localVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const localVideoContainer = localVideoRef.current!;

    if (!localVideoStream) {
      removeAllChildNodes(localVideoContainer);
      localVideoContainer.hidden = true;
      return;
    }

    removeAllChildNodes(localVideoContainer);
    localVideoContainer.hidden = false;

    localVideoContainer.appendChild(localVideoStream.target);

    return () => {
      removeAllChildNodes(localVideoContainer);
      localVideoContainer.hidden = true;
    };
  }, [localVideoStream]);

  const remoteVideoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const remoteVideoContainer = remoteVideoRef.current;

    if (!remoteVideoContainer) {
      return;
    }

    if (!remoteVideoStream) {
      removeAllChildNodes(remoteVideoContainer);
      remoteVideoContainer.style.display = 'none';
      return;
    }

    removeAllChildNodes(remoteVideoContainer);
    remoteVideoContainer.style.display = 'block';

    for (const view of remoteVideoStream) {
      remoteVideoContainer.appendChild(view.target);
    }

    return () => {
      removeAllChildNodes(remoteVideoContainer);
      remoteVideoContainer.style.display = 'none';
    };
  }, [remoteVideoStream]);

  const hangUpCallButton = async () => {
    await endCall();
    navigate(-1);
  };

  return (
    <div style={{ display: 'flex', height: '100vh', background: '#000' }}>
      <div className={styles.remoteStreamContainer} ref={remoteVideoRef} />

      {!remoteVideoStream && (
        <h1 className={styles.callStatus}>{callStatus ? callStatus : 'Disconnected'}</h1>
      )}

      <div className={styles.actionBar}>
        <div className={styles.btnContainer}>
          <SwitchCameraButton switchCamera={switchCamera} />

          <VideoCameraButton
            isCameraEnable={!!localVideoStream}
            startVideoButton={startVideoButton}
            stopVideoButton={stopVideoButton}
          />

          <MicrophoneButton
            isMicrophoneEnable={!isMuted}
            startMicrophoneButton={startMicrophoneButton}
            stopMicrophoneButton={stopMicrophoneButton}
          />

          {/* Chiusura Chiamata */}
          <HangUpButton hangUpCallButton={hangUpCallButton} />
        </div>

        <div className={styles.localStreamContainer} ref={localVideoRef} hidden />
      </div>
    </div>
  );
}
