// std
import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useRef,
} from 'react';

// 3p
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

// app
import { ICreator } from 'interfaces';
import { ACS_ENDPOINT_URL, ENV_COMMUNICATION } from 'config';

import APIClient from 'api/ApiClient';

import { useCommunicationToken } from './useCommunicationToken';
import { useGetAllThread } from 'api/chat';

interface IChatContext {
  client: ChatClient | undefined;
  isConnected: boolean;
  onCreateChatThread: (creator: ICreator) => any; // TODO
  onSendMessage: (threadAzId: string, content: string, attachment?: any) => Promise<void>;
  onSendAttachment: (threadAzId: string, attachment: any) => Promise<void>;
  hasNewMessage: boolean;
}

// const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const chatContext = createContext<IChatContext>({} as IChatContext);

// Provider component that wraps your app and makes chat object ...
// ... available to any child component that calls useChat().
export function ChatContextProvider({ children }: { children: JSX.Element }) {
  const chat = useProvideChat();
  return <chatContext.Provider value={chat}>{children}</chatContext.Provider>;
}

// Hook for child components to get the chat object ...
// ... and re-render when it changes.
export const useChat = () => {
  return useContext(chatContext);
};

// Provider hook that creates chat object and handles state
function useProvideChat() {
  const { communicationToken } = useCommunicationToken();

  const [isConnected, setConnected] = useState<boolean>(false);

  const clientRef = useRef<ChatClient>();

  const { getAllThreadQuery } = useGetAllThread();
  const { data: threads } = getAllThreadQuery;

  const [hasNewMessage, setHasNewMessage] = useState<boolean>(false);

  useEffect(() => {
    if (!threads || !threads.length) {
      return;
    }

    const checkmarks = threads.map(({ message }) => message.checkmark);
    const hasNewMessage = checkmarks.some((checkmarks) => checkmarks === null);

    setHasNewMessage(hasNewMessage);
  }, [threads]);

  // Inizializate chat client
  useEffect(() => {
    if (!communicationToken || clientRef.current) {
      return;
    }

    const { token } = communicationToken;

    clientRef.current = new ChatClient(
      ACS_ENDPOINT_URL,
      new AzureCommunicationTokenCredential(token)
    );

    // * Init real time notification
    clientRef.current.startRealtimeNotifications();

    clientRef.current.on('realTimeNotificationConnected', () => {
      console.log('Real time notification is now connected!');
      setConnected(true);
    });

    clientRef.current.on('realTimeNotificationDisconnected', () => {
      console.log('Real time notification is now disconnected!');
      setConnected(false);
    });

    return () => {
      if (clientRef.current) {
        clientRef.current.stopRealtimeNotifications();
        clientRef.current = undefined;
        setConnected(false);
      }
    };
  }, [communicationToken]);

  /**
   * Creazione di un nuovo thread
   */
  const onCreateChatThread = useCallback(async (creator: ICreator) => {
    console.log('onCreateChatThread', creator);

    const { username } = creator;

    const { data } = await APIClient.post(ENV_COMMUNICATION.CREATE_THREAD, {
      username,
    });

    return data;
  }, []);

  // * MESSAGES

  /**
   * Invio di un nuovo messaggio
   */
  const onSendMessage = useCallback(
    async (threadAzId: string, content: string, attachment: any) => {
      console.log('onSendMessage', content);

      await APIClient.post(ENV_COMMUNICATION.CREATE_THREAD_MESSAGE(threadAzId), {
        content,
        attachment,
      });
    },
    []
  );

  const onSendAttachment = useCallback(async (threadAzId: string, attachment: any) => {
    console.log('onSendAttachment', attachment);

    await APIClient.post(ENV_COMMUNICATION.CREATE_THREAD_MESSAGE(threadAzId), {
      attachment,
    });
  }, []);

  // Return the user object and chat methods
  return Object.freeze({
    client: clientRef.current,
    isConnected,
    onCreateChatThread,
    onSendMessage,
    onSendAttachment,
    hasNewMessage,
  });
}

/*
SEND MESSAGES WITH SOCKET
const onSendMessage = useCallback(
  async (threadAzId: string, content: string) => {
    console.log('onSendMessage', content);

    const client = clientRef.current;

    if (!client || !user) {
      return;
    }

    const threadClient = client.getChatThreadClient(threadAzId);

    const sendMessageRequest = { content };

    const sendMessageOptions = {
      senderDisplayName: user.username,
      type: 'text',
    } as SendMessageOptions;

    const sendChatMessageResult = await threadClient.sendMessage(
      sendMessageRequest,
      sendMessageOptions
    );

    return sendChatMessageResult;
  },
  [clientRef, user]
);
*/
