import React from 'react';

// app
import { ACCEPTED_EXT, UPLOAD_MAX_SIZE_MB } from 'config';
import { fromByteToMb, fromMbToGb } from 'utils';

import { useAttachment } from 'api/chat';

// type Media = 'photo' | 'video';

interface FileUploadProps {
  onSuccess: (file: File, fileName: string) => void;
  onProgress?: (value: number) => void;
}

const UPLOAD_ERROR_MSG = {
  INVALID_EXT: (name: string, valid: string) =>
    `L'estensione del file ${name} non è supportata, le estensioni supportate sono ${valid}`,
  MAX_SIZE: (fileSize: string, maxFileSize: string) =>
    `La dimensione massima consentita per l'upload è di ${maxFileSize}GB, file selezionato è di ${fileSize}GB.`,
};

export const ChatAttachmentMedia: React.FC<FileUploadProps> = ({
  onSuccess,
  onProgress,
}) => {
  const { createUploadUrl, uploadFile } = useAttachment();

  function updateProgress(value: number) {
    if (onProgress) {
      onProgress(value);
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];

    // Controllo se è un estensione accettata
    if (!ACCEPTED_EXT.includes(file.type)) {
      const valid = ACCEPTED_EXT.join(',');
      alert(UPLOAD_ERROR_MSG.INVALID_EXT(file.name, valid));
      return;
    }

    // Controllo se la dimensione è valida
    const fileSizeMb = fromByteToMb(file.size);

    if (fileSizeMb > UPLOAD_MAX_SIZE_MB) {
      const fileSizeGBMax = fromMbToGb(UPLOAD_MAX_SIZE_MB).toFixed(2);
      const fileSizeGB = fromMbToGb(fileSizeMb).toFixed(2);
      alert(UPLOAD_ERROR_MSG.MAX_SIZE(fileSizeGB, fileSizeGBMax));
      return;
    }

    try {
      const { presignedUrl, fileName } = await createUploadUrl(file);

      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentComplete = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total!
          );

          updateProgress(percentComplete);
        },
      };

      await uploadFile(presignedUrl, file, config);

      onSuccess(file, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      event.target.value = '';
    }
  };

  return (
    <label
      htmlFor="chat-media-upload"
      className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700 cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        />
      </svg>
      <input
        id="chat-media-upload"
        style={{ display: 'none' }}
        type="file"
        accept="image/*, video/*"
        onChange={handleFileChange}
      />
    </label>
  );
};
