// std

// 3p
import { AxiosError } from 'axios';
import { InfiniteData, useInfiniteQuery, useQueryClient } from 'react-query';

// app
import { IChatMessage, IResponseWithCursor } from 'interfaces';
import { ENV_COMMUNICATION } from 'config';

import APIClient from '../ApiClient';
import { useCallback, useMemo } from 'react';

const getAllThreadMessage = async ({ queryKey, pageParam }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, threadId, pagingQuery] = queryKey;

  const url = ENV_COMMUNICATION.GET_ALL_THREAD_MESSAGE(threadId);

  const { data } = await APIClient.get<IResponseWithCursor<IChatMessage>>(url, {
    params: {
      ...pagingQuery,
      ...(pageParam ? pageParam : {}),
    },
  });

  return data;
};

export function useGetAllThreadMessage(threadId?: string) {
  const queryKey = useMemo(() => ['getAllThreadMessage', threadId], [threadId]);

  const getAllThreadMessageQuery = useInfiniteQuery<
    IResponseWithCursor<IChatMessage>,
    AxiosError<string, any>
  >({
    queryKey,
    queryFn: getAllThreadMessage,
    enabled: !!threadId,
    getPreviousPageParam: (lastPage) => {
      const { cursor } = lastPage;

      if (!cursor || !cursor.beforeCursor) {
        return undefined;
      }

      return { beforeCursor: cursor.beforeCursor };
    },
    refetchOnWindowFocus: true,
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        console.error('Chat non disponibile');
        return false;
      }
      return true;
    },
  });

  const queryClient = useQueryClient();

  const appenThreadMessage = useCallback(
    (message: IChatMessage) => {
      queryClient.setQueryData<
        InfiniteData<IResponseWithCursor<IChatMessage>> | undefined
      >(queryKey, (data) => {
        if (!data) {
          return data;
        }

        const newData = { ...data };
        const lastPage = newData.pages[newData.pages.length - 1];
        lastPage.data = [...lastPage.data, message];
        newData.pages[newData.pages.length - 1] = lastPage;

        return newData;
      });

      // queryClient.invalidateQueries({ queryKey: queryKey });
    },
    [queryClient, queryKey]
  );

  return { queryKey, getAllThreadMessageQuery, appenThreadMessage };
}
