import { useCallback, useEffect, useState } from 'react';

// 3p
import { useParams } from 'react-router-dom';
import { ChatMessageReceivedEvent } from '@azure/communication-chat';

// app
import { IChatMessage } from 'interfaces';
import { useChat } from 'hooks';
import { useGetAllThreadMessage, useGetThread } from 'api/chat';

import { ChatAreaContent, ChatAreaFooter, ChatAreaHeader } from 'components/common/chat';
import { AlertError, Loader } from 'components/common';

export const Conversation = () => {
  const { chatId } = useParams();

  // Load thread
  const { getThreadQuery } = useGetThread(chatId);

  const {
    data: threadQueryResult,
    isLoading: threadQueryIsLoading,
    isError: threadQueryIsError,
    // error: threadQueryError,
  } = getThreadQuery;

  // Load messages
  const { getAllThreadMessageQuery } = useGetAllThreadMessage(chatId);

  const {
    data: messageQueryResult,
    isError: messageQueryIsError,
    error: messageQueryError,
    // isLoading: messageQueryIsLoading,
    // isFetching,
    fetchPreviousPage,
    hasPreviousPage,
  } = getAllThreadMessageQuery;

  const [messages, setMessages] = useState<IChatMessage[]>([]);

  useEffect(() => {
    if (!messageQueryResult) {
      return;
    }

    setMessages(messageQueryResult.pages.flatMap((page) => page.data));
  }, [messageQueryResult]);

  const { client, isConnected } = useChat();

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    console.info('ON LISTNER MESSAGGI RICEVUTI');

    const onChatMessageReceived = (e: ChatMessageReceivedEvent) => {
      // Se il messaggio ricevuto non è di questa chat, non lo mostro
      if (chatId !== e.metadata.chatId) {
        return;
      }

      let sendercommunicationUserId;

      if (e.sender.kind === 'communicationUser') {
        sendercommunicationUserId = e.sender.communicationUserId;
      }

      let audioAttachment;
      if (e.metadata.audioAttachment) {
        audioAttachment = { audioFile: { url: e.metadata.audioAttachment } };
      }

      let photoAttachment;
      if (e.metadata.photoAttachment) {
        photoAttachment = { photoFile: { url: e.metadata.photoAttachment } };
      }

      let videoAttachment;
      if (e.metadata.videoAttachment) {
        videoAttachment = {
          // thumbnailFile: { url: e.metadata.thumbnailAttachment },
          videoFile: { url: e.metadata.videoAttachment },
        };
      }

      // TODO Typings
      const newMessage = {
        id: e.metadata.messageId,
        azId: e.id,
        content: e.message,
        sendAt: e.metadata.sendAt,
        sender: {
          username: e.senderDisplayName,
          communicationUserId: sendercommunicationUserId,
        },
        audioAttachment,
        photoAttachment,
        videoAttachment,
      } as any;

      setMessages((state) => {
        return [...state, newMessage];
      });
    };

    client!.on('chatMessageReceived', onChatMessageReceived);

    return () => {
      console.info('OFF LISTNER MESSAGGI RICEVUTI');
      client!.off('chatMessageReceived', onChatMessageReceived);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, isConnected]);

  const onScrollToTop = useCallback(async () => {
    if (hasPreviousPage) {
      await fetchPreviousPage();
    }
  }, [hasPreviousPage, fetchPreviousPage]);

  if (threadQueryIsError || messageQueryIsError) {
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AlertError error={messageQueryError} />
      </div>
    );
  }

  if (threadQueryIsLoading || !threadQueryResult) {
    return <Loader />;
  }

  return (
    <>
      <ChatAreaHeader thread={threadQueryResult} />
      <ChatAreaContent
        thread={threadQueryResult}
        messages={messages}
        onScrollToTop={onScrollToTop}
      />
      <ChatAreaFooter thread={threadQueryResult} />
    </>
  );
};
