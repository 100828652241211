// std
import { useState, useEffect, useContext, createContext, useCallback } from 'react';

// app
import { ENV_AUTH, ENV_USER, LOCAL_STORAGE_KEY } from 'config';
import { IUser } from 'interfaces';

import APIClient from 'api/ApiClient';

interface IAuthContext {
  isLoading: boolean;
  recovery: (email: string) => Promise<void>;
  signup: (
    username: string,
    email: string,
    password: string,
    isCreator: boolean,
    hasAdultConsent: boolean,
    termsConditionsPrivacyConsent: boolean,
    marketingConsent: boolean
  ) => Promise<void>;
  signin: (email: string, password: string) => Promise<void>;
  signout: () => Promise<void>;
  user: IUser | undefined;
  setUser: (user: IUser) => void;
  revokeUser: () => void;
}

// const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const authContext = createContext<IAuthContext>({} as IAuthContext);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthContextProvider({ children }: { children: JSX.Element }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

async function getUser() {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);

  if (!token) {
    return;
  }

  const {
    data: { user },
  } = await APIClient.get<{ user: IUser }>(ENV_USER.ME);

  return user;
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IUser>();

  const initUser = useCallback(async () => {
    setLoading(true);

    try {
      const user = await getUser();
      setUser(user);
    } catch (error) {
      console.error('useAuth:getUserByToken', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initUser();
  }, [initUser]);

  const signup = async (
    username: string,
    email: string,
    password: string,
    isCreator: boolean,
    hasAdultConsent: boolean,
    termsConditionsPrivacyConsent: boolean,
    marketingConsent: boolean
  ) => {
    setLoading(true);
    try {
      const reqBody = {
        username,
        email,
        password,
        isCreator,
        hasAdultConsent,
        termsConditionsPrivacyConsent,
        marketingConsent,
      };
      await APIClient.post<{ token: string }>(ENV_AUTH.SIGNUP, reqBody);
    } catch (error) {
      console.error('useAuth:signup', error);
      throw error;
    } finally {
      setLoading(false);
    }

    // if (!data?.token) {
    //   throw new Error('Login failed to retrive token');
    // }
  };

  // ... to save the user to state.
  const signin = useCallback(
    async (email: string, password: string) => {
      const reqBody = { email, password };
      const { data } = await APIClient.post<{ token: string }>(ENV_AUTH.SIGNIN, reqBody);

      if (!data?.token) {
        throw new Error('Login failed to retrive token');
      }

      // set localstorage
      localStorage.setItem(LOCAL_STORAGE_KEY.AUTH_TOKEN, data.token);

      initUser();
    },
    [initUser]
  );

  const revokeUser = useCallback(() => {
    // clear state
    setUser(undefined);
    localStorage.removeItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.COMMUNICATION_TOKEN);
  }, []);

  const signout = useCallback(async () => {
    await APIClient.post(ENV_AUTH.LOGOUT);
    revokeUser();
  }, [revokeUser]);

  const recovery = useCallback(async (email: string) => {
    setLoading(true);

    try {
      await APIClient.post(ENV_AUTH.REQUEST_ACCOUNT_RECOVERY, { email });
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  // Return the user object and auth methods
  return Object.freeze({
    user,
    setUser,
    revokeUser,
    signup,
    signin,
    signout,
    recovery,
    isLoading,
  });
}
