// std
import { Fragment, useEffect } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { SubmitHandler, useForm } from 'react-hook-form';

// app
import { IPayInCouponForm, IPayInCouponResponse } from 'interfaces';
import styles from 'styles';

import { useRedeemCoupon } from 'api/coupon';

import { ContentLoading } from 'components/common/payment/ContentLoading';
import { AlertError } from 'components/common/alert';

interface IWalletCouponModalProps {
  open: boolean;
  onSuccess?: (data: IPayInCouponResponse) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export function WalletCouponModal(props: IWalletCouponModalProps): JSX.Element {
  const { open, onSuccess, onError, onClose } = props;

  const { createRedeemCouponMutation } = useRedeemCoupon();

  const {
    mutateAsync,
    isLoading: isLoadingCreateRedeemCouponMutation,
    isError: isErrorCreateRedeemCouponMutation,
    error: errorRedeemCouponMutation,
    reset: resetRedeemCouponMutation,
  } = createRedeemCouponMutation;

  const {
    register: registerRedeemCouponForm,
    handleSubmit: handleSubmitRedeemCouponForm,
    reset: resetRedeemCouponForm,
  } = useForm<IPayInCouponForm>();

  const onSubmit: SubmitHandler<IPayInCouponForm> = async (data) => {
    try {
      const response = await mutateAsync(data);
      if (onSuccess) onSuccess(response);
    } catch (error) {
      if (onError) onError(error);
    }
  };

  useEffect(() => {
    if (!open) {
      resetRedeemCouponMutation();
      resetRedeemCouponForm();
    }
  }, [open, resetRedeemCouponForm, resetRedeemCouponMutation]);

  const renderData = () => (
    <form onSubmit={handleSubmitRedeemCouponForm(onSubmit)}>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className={styles.modal.title}>
            Riscatta coupon
          </Dialog.Title>

          <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
            {isErrorCreateRedeemCouponMutation && (
              <div className="sm:col-span-3">
                <AlertError error={errorRedeemCouponMutation} />
              </div>
            )}
            <div className="sm:col-span-3">
              <label htmlFor="code" className={styles.input.label}>
                Codice
              </label>
              <div className={styles.input.containerRelative}>
                <input
                  id="code"
                  className=" sm:text-lg focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  type="text"
                  autoComplete="none"
                  {...registerRedeemCouponForm('code', {
                    required: true,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modal.footer}>
        <button type="submit" className={styles.modal.button1}>
          Riscatta
        </button>
        <button type="button" className={styles.modal.button2} onClick={onClose}>
          Annulla
        </button>
      </div>
    </form>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={styles.modal.form}>
              {isLoadingCreateRedeemCouponMutation ? <ContentLoading /> : renderData()}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
