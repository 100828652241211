// std
import { Fragment, useEffect, useMemo, useState } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// app
import { EPOCH_PAYMENT_INFO, PaymentGatewayEnum } from 'config/Enum';
import { DEFAULT_GATEWAY } from 'config/Config';

import { IPayInTipForm, IPayInResponse } from 'interfaces';
import styles from 'styles';

import { useDebounce } from 'hooks';

import { useGetAllPayInSource, useCreatePayInTopUp } from 'api/transfer';

import {
  ContentPaymentSuccess,
  ContentLoading,
  ContentError,
} from 'components/common/payment';

import { AlertInfo } from 'components/common/alert';
import RadioCardsImage from 'components/common/radio/RadioCardsImage';

interface IWalletTopUpModalProps {
  open: boolean;
  onSuccess?: (data?: IPayInResponse) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export function WalletTopUpModal(props: IWalletTopUpModalProps): JSX.Element {
  const { open, onSuccess, onError, onClose } = props;

  const [paymentInResponse, setPaymentInResponse] = useState<IPayInResponse>();

  const { createPayInTopUpMutation } = useCreatePayInTopUp();

  const {
    mutateAsync,
    isLoading: isLoadingCreatePayInUrl,
    isError: isErrorCreatePayInUrl,
  } = createPayInTopUpMutation;

  const defaultValues = useMemo(
    () => ({
      gateway: DEFAULT_GATEWAY,
    }),
    []
  );

  const { register, control, handleSubmit, reset, watch, setValue } =
    useForm<IPayInTipForm>({
      defaultValues: defaultValues,
    });

  const gateway = watch('gateway');
  const isPaymentGatewayEpoch = gateway === PaymentGatewayEnum.EPOCH;

  const amountValue = watch('amount');
  const amountValueDeferred = useDebounce<number>(amountValue);

  const queryParams = useMemo(() => {
    if (!amountValueDeferred) return undefined;
    return { amount: amountValueDeferred };
  }, [amountValueDeferred]);

  const { getAllPayInSourceQuery } = useGetAllPayInSource(queryParams);
  const { data: dataGetAllPayInSource } = getAllPayInSourceQuery;

  useEffect(() => {
    setValue('gateway', DEFAULT_GATEWAY);
  }, [dataGetAllPayInSource, setValue]);

  const onSubmit: SubmitHandler<IPayInTipForm> = async (data) => {
    try {
      const response = await mutateAsync(data);
      setPaymentInResponse(response);
    } catch (error) {
      if (onError) onError(error);
    }
  };

  // Set Default data and clean
  useEffect(() => {
    if (!open) {
      setPaymentInResponse(undefined);
    }
    reset(defaultValues);
  }, [defaultValues, open, reset]);

  const renderData = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className={styles.modal.title}>
            Ricarica
          </Dialog.Title>

          <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <label htmlFor="amount" className={styles.input.label}>
                Importo
              </label>
              <div className={styles.input.containerRelative}>
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">€</span>
                </div>
                <input
                  id="amount"
                  className=" sm:text-lg focus:ring-pink-500 focus:border-pink-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                  type="number"
                  min="3"
                  max="400"
                  step="any"
                  {...register('amount', {
                    required: true,
                    valueAsNumber: true,
                    max: 400,
                    min: 3,
                  })}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                    EUR
                  </span>
                </div>
              </div>
              <p className={styles.input.descr}>Importo minimo €3.00</p>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="gateway" className={styles.input.label}>
                Modalità di pagamento
              </label>
              <Controller
                name="gateway"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <RadioCardsImage
                    onChange={onChange}
                    value={value}
                    options={dataGetAllPayInSource}
                  />
                )}
              />
            </div>
            {isPaymentGatewayEpoch && (
              <div className="sm:col-span-3">
                <AlertInfo message={EPOCH_PAYMENT_INFO} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.modal.footer}>
        <button type="submit" className={styles.modal.button1}>
          Ricarica
        </button>
        <button type="button" className={styles.modal.button2} onClick={onClose}>
          Annulla
        </button>
      </div>
    </form>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={styles.modal.form}>
              {isLoadingCreatePayInUrl ? (
                <ContentLoading />
              ) : paymentInResponse ? (
                <ContentPaymentSuccess
                  payment={paymentInResponse}
                  onSuccess={onSuccess}
                />
              ) : isErrorCreatePayInUrl ? (
                <ContentError />
              ) : (
                renderData()
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
