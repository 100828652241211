// 3p
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

// app
import { AppRouter } from 'views/app';
import { WebRouter } from 'views/web';
import { AdminRouter } from 'views/admin';

import {
  ToastContextProvider,
  AuthContextProvider,
  ChatContextProvider,
  VideoCallContextProvider,
} from 'hooks';
import { IncomingCallContextProvider } from 'components/common/chat/video';

import ScrollToTop from 'components/common/ScrollToTop';
import AdultConsentModal from 'components/common/AdultConsentModal';

import { PrivateRoute } from 'utils';

import * as CONFIG from './config/Config';

import { MainLayout } from 'layouts/web';

import './App.css';
import { useCallback, useEffect } from 'react';

import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from 'config/Enviroment';

import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { useCookies } from 'react-cookie';

const queryClient = new QueryClient();

function App() {
  const [cookies] = useCookies(['vieniora-com-privacy']);

  const enableAnalytics = useCallback(() => {
    if (GTM_ID) {
      TagManager.dataLayer({
        dataLayer: [
          'consent',
          'update',
          {
            ad_storage: 'granted',
            analytics_storage: 'granted',
          },
        ],
      });
    }
  }, []);

  const declineAnalytics = useCallback(() => {
    if (GTM_ID) {
      TagManager.dataLayer({
        dataLayer: [
          'consent',
          'update',
          {
            ad_storage: 'denied',
            analytics_storage: 'denied',
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    if (GTM_ID) {
      const tagManagerArgs = {
        gtmId: GTM_ID,
        auth: GTM_AUTH,
        preview: GTM_PREVIEW,
        consentSettings: {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        },
      } as TagManagerArgs;

      TagManager.initialize(tagManagerArgs);
    }
  });

  useEffect(() => {
    const enabledAnalytics = cookies['vieniora-com-privacy'];

    if (enabledAnalytics) {
      enableAnalytics();
    }
  }, [cookies, enableAnalytics]);

  return (
    <>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <ToastContextProvider>
            <IncomingCallContextProvider>
              <VideoCallContextProvider>
                <ChatContextProvider>
                  <Routes>
                    <Route element={<PrivateRoute />}>
                      <Route path="/admin/*" element={<AdminRouter />} />
                    </Route>
                    <Route element={<PrivateRoute />}>
                      <Route path="/app/*" element={<AppRouter />} />
                    </Route>
                    <Route element={<MainLayout />}>
                      <Route path="/*" element={<WebRouter />} />
                    </Route>
                  </Routes>
                </ChatContextProvider>
              </VideoCallContextProvider>
            </IncomingCallContextProvider>
          </ToastContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
      <AdultConsentModal />
      <CookieConsent
        {...CONFIG.PRIVACY_COOKIE_PROPS}
        onAccept={enableAnalytics}
        onDecline={declineAnalytics}
      >
        {CONFIG.PRIVACY_COOKIE_MESSAGE}
      </CookieConsent>
    </>
  );
}

export default App;
